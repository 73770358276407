// Places a live bid
export const updateCurrentBidMutation = `mutation updateCurrentBid($userId: String!, $bidAmount: Int!, $itemId: String!) {
  updateCurrentBid(input: {
    BidAmount: $bidAmount,
    ItemId: $itemId,
    UserId: $userId,
    FullName: "{FullNameFromToken}",
  }) {
    CurrentBid
    BidCount
    CurrentHighBidder
    ItemId
    AuctionId
  }
}`
export const registerForAuctionMutation = `mutation registerForAuction($userId: String!, $auctionId: String!, $textAlertNumber: String, $isCheckedIn: Boolean) {
  registerUser(input: {AuctionId: $auctionId, UserId: $userId, TextAlertNumber: $textAlertNumber, IsCheckedIn: $isCheckedIn}) {
    RegistrationId
    EventHubRegistrationId
    PdfBase64
    PdfUrl
    QrBase64
    ResponseData
  }
}`
export const updateUserRegistrationMutation = `mutation updateUserRegistration($auctionId: String!, $textAlertNumber: String, $isCheckedIn: Boolean) {
  updateUserRegistration(input: {AuctionId: $auctionId, UserId: "{UserIdFromToken}", TextAlertNumber: $textAlertNumber, IsCheckedIn: $isCheckedIn}) {
    RegistrationId
    EventHubRegistrationId
    ResponseData
  }
}`
export const updateUserFavoritesMutation = `mutation updateUserFavorites($auctionId: String!, $itemId: String!, $add: Boolean!) {
  updateUserFavorites(input: {UserId: "{UserIdFromToken}", AuctionId: $auctionId, ItemId: $itemId, Add: $add}) {
    RegistrationId
    ResponseData
  }
}`
export const unregisterFromAuctionMutation = `mutation unregisterFromAuction($registrationId: String!) {
  unregisterUser(input: {RegistrationId: $registrationId}) {
    RegistrationId
    EventHubRegistrationId
    ResponseData
  }
}`

// Creates entries in auctions-cognito-login-mapping and auctions-users
export const createUserMutation = `mutation createUser {
  createUser {
    UserId
  }
}`
// Updates an auctions user after creation or sign in (back end checks CRM every time)
export const updateUserMutation = `mutation updateUser($firstName: String, $lastName: String, $phoneNumber: String, $emailAddress: String, $userData: AWSJSON) {
  updateUser(input: {
    UserId: "{UserIdFromToken}",
    CrmId: "{CrmIdFromToken}",
    FirstName: $firstName,
    LastName: $lastName,
    ContactPhone: $phoneNumber,
    ContactEmail: $emailAddress,
    UserData: $userData,
  }) {
    UserId
    CrmId
    FirstName
    LastName
    ContactEmail
    ContactPhone
    Address {
      Street1
      Street2
      City
      State
      PostCode
      Country
    }
    UserData
    isTestUser
  }
}`
// Deletes all entries in auctions-cognito-login-mapping and the entry in auctions-users
export const deleteUserMutation = `mutation deleteUser($cognitoLoginToDelete: String!) {
  deleteUser(input: {CognitoLoginToDelete: $cognitoLoginToDelete}) {
    UserId
    UserData
  }
}`

export const createAuctionMutation = `mutation createAuction($input: CreateAuctionInput!) {
  createAuction(input: $input) {
    AuctionId
  }
}`
export const updateAuctionMutation = `mutation udpateAuction($input: UpdateAuctionInput!) {
  updateAuction(input: $input) {
    AuctionId
    ResponseData
  }
}`
export const updateSolicitationReviewMutation = `mutation updateSolicitationReview($auctionId: String!, $reviewStatus: String!) {
  updateSolicitationReview(input: {
    AuctionId: $auctionId
    ReviewStatus: $reviewStatus
  }) {
    AuctionId
    ResponseData
  }
}`
export const createAuctionItemMutation = `mutation createAuctionItem($input: CreateAuctionItemInput!) {
  createAuctionItem(input: $input) {
    ItemId
  }
}`
export const updateAuctionItemMutation = `mutation updateAuctionItem($input: UpdateAuctionItemInput!) {
  updateAuctionItem(input: $input) {
    ItemId
  }
}`
export const deleteAuctionItemMutation = `mutation deleteAuctionItem($itemId: String!) {
  deleteAuctionItem(input: { ItemId: $itemId }) {
    ItemId
  }
}`

export const savePaymentMethodMutation = `mutation savePaymentMethod($nonce: String!)  {
  savePaymentMethod(input: {Nonce: $nonce}) {
    Success
    ResponseData
  }
}`

export const skipPaymentMethodMutation = `mutation skipPaymentMethod {
  skipPaymentMethod {
    Success
    ResponseData
  }
}`

export const makePaymentMutation = `mutation makePayment($auctionId: String!, $paymentMethodDateAdded: String!, $totalAmount: String!, $address: AddressInput) {
  makePayment(input: {
    AuctionId: $auctionId
    DateAdded: $paymentMethodDateAdded
    TotalAmount: $totalAmount
    Address: $address
  }) {
    Success
    ErrorKey
    ErrorMsg
    ResponseData
  }
}`

export const deleteAuctionMutation = `mutation deleteAuction($auctionId: String!) {
  deleteAuction(input: { AuctionId: $auctionId }) {
    AuctionId
    ResponseData
  }
}`
export const adminMakePaymentMutation = `mutation adminMakePayment($auctionId: String!, $endUserId: String!, $totalAmount: String!, $itemIds: [String]!, $address: AddressInput) {
  adminMakePayment(input: {
    AuctionId: $auctionId
    EndUserId: $endUserId,
    TotalAmount: $totalAmount
    ItemIds: $itemIds,
    Address: $address
  }) {
    Success
    ErrorKey
    ErrorMsg
    ResponseData
  }
}`

export const sendPaymentRemindersMutation = `mutation sendPaymentReminders($auctionId: String!)  {
  sendPaymentReminders(input: {AuctionId: $auctionId}) {
    AuctionId
    ResponseData
  }
}`

export const sendSolicitationReviewRequestMutation = `mutation sendSolicitationReviewRequest($auctionId: String!)  {
  sendSolicitationReviewRequest(input: {AuctionId: $auctionId}) {
    AuctionId
    ResponseData
  }
}`

// preview stuff
export const cleanupPreviewAuctionMutation = `mutation cleanupPreviewAuction($auctionId: String!) {
  cleanupPreviewAuction(input: { AuctionId: $auctionId }) {
    AuctionId
    ResponseData
  }
}`

