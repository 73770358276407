import { useEffect, useState, useCallback } from 'react'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { showLoading } from 'services/util'
import { history } from 'services/history.js'

import { urlQueryParser } from '../util/urlQueryParser'
import { getAuctionStatus } from '../util/dateString'

import { adminGetAuctionByCode } from './auctionsadminutil'

import adminCss from './sass/adminpage.module.scss'

import TabNav from 'sites/auctions/components/TabNav'
import AdminAuctionDetail from './AdminAuctionDetail'
import AdminAuctionItems from './AdminAuctionItems'
import PreviewPublish from './PreviewPublish'
import AuctionDetailsAlert from './AuctionDetailsAlert'
import AdminBreadcrumbs from './AdminBreadcrumbs'
import ClosedBidding from './ClosedAuction/ClosedBidding'
import LiveAttendeeView from './UserDetail/LiveAttendeeView'
import LiveAuctionView from './UserDetail/LiveAuctionView'
import PreBidding from './UserDetail/PreBidding'
import PreAttendees from './UserDetail/PreAttendees'
import ClosedItems from './ClosedAuction/ClosedItems'
 
export default function AdminAuctionTabs(props) {
  
  const [tabTitles, setTabTitles] = useState([])
  const [tabPanels, setTabPanels] = useState([])
  const [goToIndex, setGoToIndex] = useState(0)

  //to refresh query on changes from the Status update
  const queryClient = useQueryClient()

  const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionTabDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  
  const liveStatus = getAuctionStatus(auctionDetail?.StartTime, auctionDetail?.EndTime)

  const refreshAdminAuctionQuery = useCallback(() => {
    queryClient.invalidateQueries(["adminAuctionTabDetail"])
  }, [queryClient])

  useEffect(() => {
    switch(liveStatus) {
      case "ended":
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        setTabPanels([<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
                      <ClosedItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />,
                      <ClosedBidding auctionCode={auctionCode} />,
                      <LiveAttendeeView auctionCode={auctionCode} />])    
                      
        break;
      case "started":
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        let startedPanels = [<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
                      <AdminAuctionItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />]


        // bidding and atendee tab depends on if auction is in preview or not
        if (auctionDetail?.Status === "PREVIEW") {
          startedPanels.push(<PreBidding />)
          startedPanels.push(<PreAttendees />)
        }
        else {
          startedPanels.push(<LiveAuctionView auctionCode={auctionCode} />)
          startedPanels.push(<LiveAttendeeView auctionCode={auctionCode} />)
        }

        setTabPanels(startedPanels)

                      
        break;
      case "before":
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        
        let beforePanels = [<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
          <AdminAuctionItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />,
          <PreBidding />]
        
        // assign attendee tab based on auction public status
        // if auction is not public, will need to be prebidding
        if (auctionDetail?.Status === 'PUBLIC') beforePanels.push(<LiveAttendeeView auctionCode={auctionCode} />)
        else beforePanels.push(<PreAttendees />)

        setTabPanels(beforePanels)
        break;
      default:
        setTabTitles(['Auction Details', 'Auction Items', 'View Bidding', 'View Attendees'])
        setTabPanels([<AdminAuctionDetail history={props.history} auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} setGoToIndex={setGoToIndex} />,
                      <AdminAuctionItems auction={auctionDetail} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />,
                      <PreBidding />,
                      <PreAttendees />])
      }

  }, [liveStatus, auctionDetail, auctionCode, props.history, refreshAdminAuctionQuery])

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }
  }, [auctionDetail, error, isLoading])

  return (
      <div className={adminCss['admin-auction-page-container']}>
      <AdminBreadcrumbs auctionDetail={auctionDetail} liveStatus={liveStatus} />

        <div className={adminCss['admin-auction-page-header']}>
          <button
            onClick ={(e) => {history.push({
              pathname: '/auctionhome',
              search: `?auctionId=${auctionDetail.AuctionCode}`,
            })}}
            className={adminCss['admin-auction-page-header-button']}
          >{auctionDetail?.Title}</button>
          <PreviewPublish auctionDetail={auctionDetail} liveStatus={liveStatus} refreshAdminAuctionQuery={refreshAdminAuctionQuery} />
        </div>

        <AuctionDetailsAlert liveStatus={liveStatus} auctionDetail={auctionDetail} />

        <div className={adminCss['admin-auction-page-tabnav']} >
          <TabNav titles={tabTitles} panels={tabPanels} goToIndex={goToIndex} />
        </div>
    </div>
  )
}