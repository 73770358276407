import { useEffect, useState} from 'react'

import { sortSpan, handleSort, performSort } from '../auctionsadminutil'

import css from './sass/liveauction.module.scss'

import UserBidHistoryTable from './UserBidHistoryTable'

export default function LiveBiddingBiddersTable({ users, auctionDetail }) {

  const [displayUsers, setDisplayUsers] = useState([])
  const [sortOptions, setSortOptions] = useState({ key: '', dir: 'asc' })
  const tableFormatter = [
    ['FullName', 'Full Name'],
    ['numberOfBids', 'Bid Count'],
    ['totalBidAmount', 'Total Currently Winning']
  ]

  useEffect(() => {
    if (users && auctionDetail) {
      setDisplayUsers(users)
    }
  }, [users, auctionDetail])
  
  const sortedUsers = performSort(displayUsers, sortOptions)

  return (
      <table className={css['data-table']}>
        <thead>
          <tr>
            {tableFormatter.map((ele) => {
              return (
                <th onClick={() => handleSort(ele[0], sortOptions, setSortOptions)} key={ele[1]}>
                  {ele[1]} {sortSpan(ele[0], sortOptions)}</th>
                )})}
          </tr>
        </thead>
        <tbody>
          {sortedUsers && sortedUsers.map((user) => {
            return (
              <tr key={user.UserId}>
                <td> <UserBidHistoryTable auctionDetail={auctionDetail} user={user} /> </td>
                <td>{user.numberOfBids}</td>
                <td>${user.totalBidAmount}</td>
              </tr>)
          })}
        </tbody>
      </table>
  )
}