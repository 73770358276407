import { useCallback, useEffect } from 'react'
import { useSelector } from "react-redux"

import { showLoading } from 'services/util'
import { getLoggedInUser } from 'redux/selectors';

import { handleEscapeKey } from 'sites/auctions/components/utils'
import NumberRow from './NumberRow'
import css from '../../components/sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function ConfirmMarkAsPaid({setShowConfirmModal, updateItem, data}) {

  const loggedInUser = useSelector(getLoggedInUser)

  const toggleModal = useCallback(() => {
      setShowConfirmModal(false)
    }, [setShowConfirmModal])
  
  useEffect(() => {
    const onKeyDown = (event) => handleEscapeKey(event, toggleModal)
    //add event listener for when modal is mounted
    document.addEventListener('keydown', onKeyDown)
    //clean up when modal is unmounted
    return () => document.removeEventListener('keydown', onKeyDown)

  }, [toggleModal])

  const performConfirm = (test) => {

    const input = {
      ItemId: data.itemsMarkedPaid[0],
    }

    if (test) {
      input['Paid'] = null
      input['PaymentDetails'] = null
    } else {
      input['Paid'] = true
      input['PaymentDetails'] = JSON.stringify({ message: "Marked as paid by admin: " + loggedInUser.cognitoUser.username })
    }

    showLoading(true)
    updateItem(input)
  }
  

  return (
    <div className={css['modal-container']}>
      <div className={themeCss['modal-background']} onClick={()=>toggleModal()}> </div>
      <div className={`${css['modal-content']} }`}>

        <div className={css['withdata-body']}>
          <div className={css['withdata-text']}>
            <h3>CONFIRM MARKING ITEMS AS PAID</h3>

            {data.itemsMarkedPaid && <>
              <NumberRow number={data.itemsMarkedPaid.length} dollar={false} text={'ITEM(S) BEING MARKED AS PAID'} />
              <NumberRow number={data.totalMarkedPaid} dollar={true} text={'TOTAL BEING MARKED AS PAID'} />
            </>}
            
          </div> 
        </div>

        {/* {data.errorMessage && <p>Error</p>} */}

        <div className={css['button-options']}>
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${themeCss['centered-button']}`}
            onClick={()=>toggleModal()}>Cancel</button>          
          <button className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> performConfirm()}>Confirm Mark As Paid</button>
          
          {/* 
          //leave in for testing purposes
          <button 
            className={`${themeCss['FilledYellow']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> performConfirm(true)}>Test-Unpaid</button> */}

        </div>
      </div>    
    </div>
  )
}