import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux"

import { getAuctionUser } from '../redux/selectors'
import { updateUserFavorites } from '../api'

import css from './sass/itemcard.module.scss'
import heartOutline from '../components/images/heart-outline.svg'
import heartFilled from '../components/images/heart-filled.svg'

export default function FavoriteItem( {auctionId, itemId} ) {

  const auctionUser = useSelector(getAuctionUser)

  const [favorited, setFavorited] = useState()
  const [displayText, setDisplayText] = useState()
  const [displayImg, setDisplayImg] = useState()

  useEffect(() => {
    if (!auctionUser)
      return

    const favorites = new Set((auctionUser.MyAuctions.find(auction => auction.AuctionId === auctionId) || []).Favorites || [])
    setFavorited(favorites.has(itemId))

  }, [auctionUser, auctionId, itemId])

  useEffect(() => {
    if (favorited) {
      setDisplayText('Favorited')
      setDisplayImg(heartFilled)
    }
    else {
      setDisplayText('Add as Favorite')
      setDisplayImg(heartOutline)
    }
  }, [favorited])

  const flipFavorite = async (currentState) => {

    //optimistically set icon so user sees instant response
    setFavorited(!currentState)

    const response = await updateUserFavorites(auctionUser, auctionId, itemId, !currentState)

    if (response?.data && !response?.errors) {
      // do nothing, success
    }
    else {
      // show user some message I guess?
      console.error("OOPS!", response)
      // set back to original state so user knows it didn't work
      setFavorited(currentState)
    }
  }
  
  return (
    <div className={css['favorite']} onClick={() => flipFavorite(favorited)}>
      <img alt={(favorited ? "Unfavorite" : "Favorite") + " this item"} className={css['auction-ended-paiditems-img']} src={displayImg} />
      <p>
        {displayText}
      </p>
    </div>
  )
}
