import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { getImageUrl } from '../util/images'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/itemcard.module.scss'

export default function ItemCard({item, auction, auctionState }) {

  const [imageUrl, setImageUrl] = useState()

  const linkObj = {
    itemCode: item.ItemCode,
    auctionCode: auction.AuctionCode,
    link: 'itemPage',
    browse: true,
  }

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction, item])


  return (
    <div className={`${css['card-container']} ${themeCss['shadowed']}`}>
      <Link className={css['card-image']} to={{ pathname: '/browse/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>
        <img src={imageUrl} alt={`${item.Title}`} />
      </Link>

      <Link className={`${css['card-title']} ${themeCss['truncate-twoline']}`} to={{ pathname: '/browse/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>
        <p className={`${css['card-title']} ${themeCss['truncate-twoline']}`}>
          {item.Title}
        </p>
      </Link>

      <CustomMarkdown text={item.Description} noLineBreaks={true} cssClass={`${css['card-description']} ${themeCss['truncate-fourline']}`} linkObj={linkObj} />

    </div>
  )
}