import { useState, useEffect } from 'react'
import { useQuery } from "@tanstack/react-query"

import { adminListAuctions } from '../auctionsadminutil'

import css from './sass/adminpage.module.scss'

import AuctionCard from '../AuctionCard'
import { showLoading } from 'services/util'


export default function AdminPublicAuctionPage(props) {

  const [liveAuctionList, setLiveAuctionList] = useState([])
  const [endedAuctionList, setEndedAuctionList] = useState([])
  const [upcomingAuctionList, setUpcomingAuctionList] = useState([])

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["allAuctionListInPublic"],
    queryFn: async () => await adminListAuctions('PUBLIC'),
    staleTime: 0
  })

  useEffect(() => {
    showLoading(isLoading)

    if (error) {
      console.log("Auctions not loaded: ", error)
      console.log(error.errors) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (auctions) {
      console.log('loaded results, ', auctions)
    }
  }, [auctions, error, isLoading])

  useEffect(() => {
    if (!auctions) return

    // const allUpcomingAuctions = auctions.sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0).map((auction) => { return {...auction, 'displayData': false}})
    const sortedAuctions = [...auctions].sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime))
    // const allPublicAuctions = sortedAuctions.filter((auction) => auction.Status === 'PUBLIC')

    let upcomingAuctions = []
    let liveAuctions = []
    let endedAuctions = []

    const now = new Date()
    const nowStr = now.toISOString()
    
    sortedAuctions.forEach((auction) => {
      
      if (auction.StartTime < nowStr) {
        if (auction.EndTime < nowStr) endedAuctions.push(auction)
        else liveAuctions.push(auction)
      }
      else upcomingAuctions.push(auction)
    })

    setLiveAuctionList(liveAuctions)
    setEndedAuctionList(endedAuctions)
    setUpcomingAuctionList(upcomingAuctions)
  }, [auctions])

  return (
    <div>
      {liveAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Live Auctions</h2>
        {liveAuctionList.map((auction) => <AuctionCard state={'live'} auction={auction} key={auction.AuctionId} />)}
      </div>}

      {upcomingAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Upcoming Auctions</h2>
        {upcomingAuctionList.map((auction) => <AuctionCard state={'upcoming'} auction={auction} key={auction.AuctionId} />)}
      </div>}

      {upcomingAuctionList && <div className={css['auction-blocks']}>
        <h2 className={css['auction-headers']}>Ended Auctions</h2>
        {endedAuctionList.map((auction) => <AuctionCard state={'ended'} auction={auction} key={auction.AuctionId} />)}
      </div>}

      {/* Demo: to link between admin pages, use props.history, to link to pages outside admin, use imported history*/}
{/*      <button onClick={(e) => {history.push({pathname: '/auctions'})}}>Demo Link to non-admin Page</button><br/>
      <button onClick={(e) => {props.history.push({pathname: '/admin/createauction'})}}>Demo New Auction Image Upload</button><br/>
*/}
      {/* Link will always use props.history */}
      {/*<Link to={{ pathname: '/admin/createauction' }}>Demo Auction Image Upload</Link>*/}

    </div>
  )
}