import { useState } from 'react'

import { sortSpan, handleSort, performSort } from '../auctionsadminutil'

import css from '../sass/adminitem.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import ClosedItemDisplayRow from './ClosedItemDisplayRow'

export default function ClosedItemDisplay( {selectedAuction} ) {

  const [sortOptions, setSortOptions] = useState({ key: '', dir: 'asc' })

  //for the copy to clipboard, and the mapping of table headers with functions
  const tableFormatter = [
    ['Title', 'Item Title'],
    ['Value', 'Fair Market Value'],
    ['StartingBid', 'Starting Bid'],
    ['MinRaise', 'Min. Raise'],
    ['ItemCode', 'Item #'],
    ['Donor', 'Donated By'],
    ['BidCount', 'Bid Count'],
    ['CurrentBid', 'Current Bid'],
    ['FullName', 'User']
  ]

  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!selectedAuction) return (<></>)

  const mappedItems = selectedAuction?.Items.map((item) => {
    return {
      ...item,
      ItemCode: parseInt(item.ItemCode),
      FullName: `${item?.CurrentHighBidderInfo?.FirstName} ${item?.CurrentHighBidderInfo?.LastName}`
    }
  })

  const sortedItems = performSort(mappedItems, sortOptions)
  
  // make sure to update this if the columns change
  const copyAttendeesToClipboard = () => {

    const textToCopy = [tableFormatter.map(headerName => headerName[1]).join('\t')];
    sortedItems.forEach(row => {

      const rowToPush = tableFormatter.map((column) => row[column[0]])
      textToCopy.push(rowToPush.join('\t'))
    })
    navigator.clipboard.writeText(textToCopy.join('\n'))
  }

  if (selectedAuction.Items.length === 0) return <p>No Items, add some!</p>
  
  return (
    <>
      <div className={css['admin-items-container']}>
        <p className={themeCss['boldGreen']}>Items in bold green are above Fair Market Value:</p>
        <table className={themeCss['data-table']}>
          <thead>
            <tr>
              <th></th>
              {tableFormatter.map((ele) => {
              return (
                <th onClick={() => handleSort(ele[0], sortOptions, setSortOptions)} key={ele[1]}>
                  {ele[1]} {sortSpan(ele[0], sortOptions)}</th>
                )})}
            </tr>
          </thead>
          <tbody>
            {sortedItems && sortedItems.map((item) => {
              return (
                <ClosedItemDisplayRow key={item.ItemId} auction={selectedAuction} item={item} />
                )
              })}
          </tbody>
        </table>

        <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${css['live-auction-clipboardbutton']}`} onClick={copyAttendeesToClipboard}>
          Copy List to Clipboard
        </button>

      </div>
    </>
  )
}