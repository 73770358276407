import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import AuctionEdit from './AuctionEdit'
import AuctionDeleteButton from './AuctionDeleteButton'

export default function AdminAuctionDetail(props) {

  if (!props.auction) return <> </>
  
  return (
    <div className={adminCss['admin-auction-page-tab-container']}>
      <h3 className={adminCss['admin-auction-page-tab-header']}>
        Auction Details
      </h3>

      <AuctionEdit auction={props.auction} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
      {props.auction.Status === 'PREVIEW' && <AuctionDeleteButton history={props.history} auction={props.auction} location={'bottom'} />}
      <button className={`${themeCss['FilledWhite']} ${themeCss['button']} ${themeCss['centered-button']} ${adminCss['admin-auction-page-tab-button']}`}
        onClick={() => props.setGoToIndex(1)}>
          Go To Items</button>
    </div>
  )
}