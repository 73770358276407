import { useState, useEffect, useCallback } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'
import checkmark from 'images/check-green.svg'

import { updateAuctionItemMutation } from '../graphql/Mutations'
import { graphql  } from '../api'

import css from './sass/createedit.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import UploadFiles from './UploadFiles'
import { getImageUrl } from '../util/images'
import { handleEscapeKey } from '../components/utils'

const emptyFn = () => {}

export default function ItemEdit({ auction, item, refreshAdminAuctionQuery, setHideCloseModalButton, setShowModal }) {

  if (!setHideCloseModalButton) setHideCloseModalButton = () => {}

  const [description, setDescription] = useState(item.Description)
  const [donor, setDonor] = useState(item.Donor)
  const [imageFilename, setImageFilename] = useState(item.ImageFilename)
  const [itemCode, setItemCode] = useState(item.ItemCode)
  const [minRaise, setMinRaise] = useState(item.MinRaise)
  const [startingBid, setStartingBid] = useState(item.StartingBid)
  const [title, setTitle] = useState(item.Title)
  const [value, setValue] = useState(item.Value)

  const [imageUrl, setImageUrl] = useState('')
  const [showEditImage, setShowEditImage] = useState(false)

  const [disableEdit, setDisableEdit] = useState(true)

  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const [updatedItem, setUpdatedItem] = useState()
  const [isImageSelected, setIsImageSelected] = useState(false)
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  
  const italicLabel = '<i>text to italicize</i>'

  const toggleModal = useCallback(() => {
      setShowModal(false)
    }, [setShowModal])
  
    useEffect(() => {
      const onKeyDown = (event) => handleEscapeKey(event, toggleModal)
      //add event listener for when modal is mounted
      document.addEventListener('keydown', onKeyDown)
      //clean up when modal is unmounted
      return () => document.removeEventListener('keydown', onKeyDown)
  
    }, [toggleModal])

  useEffect(() => {
    if (description !== item.Description ||
    donor !== item.Donor ||
    imageFilename !== item.ImageFilename ||
    itemCode !== item.ItemCode ||
    minRaise !== item.MinRaise ||
    startingBid !== item.StartingBid ||
    title !== item.Title ||
    value !== item.Value) {
      setDisableEdit(false)
    } else setDisableEdit(true)
  }, [item, description, donor, imageFilename, itemCode, minRaise, startingBid, title, value])

  
  const {data: updateItemResponse, error, isPending, mutate: updateItem } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionItemMutation, variables: {input: input} })
    },
  })

  useEffect(() => {
    setHideCloseModalButton(isImageSelected)
  }, [isImageSelected, setHideCloseModalButton])

  const imageUploadResult = (success) => {
    setIsImageUploaded(success)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    
    const input = {
      ItemId: item.ItemId,
      Title: title.trim(),
      ImageFilename: imageFilename.trim(),
      Donor: donor.trim(),
      MinRaise: minRaise,
      Description: description.trim(),
      StartingBid: startingBid,
      Value: value
    }

    console.log('input', input)

    setUpdatedItem(input)
    updateItem(input)
  }

    // use effect for auction banner image
    useEffect(() => {
      const url = getImageUrl(auction?.AuctionCode, item.ItemCode, imageFilename)
      setImageUrl(url)
    }, [auction, item, imageFilename])

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Item not updated: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateItemResponse && !updateSuccess) {
      console.log(updateItemResponse)
      console.log('Item updated: ', updateItemResponse.data.updateAuctionItem.ItemId)
      const newItem = {...updatedItem, ItemId: updateItemResponse.data.updateAuctionItem.ItemId, ItemCode: item.ItemCode}
      setUpdatedItem(newItem)
      console.log('set updated item again with the new stuff', newItem)
      refreshAdminAuctionQuery()
      setUpdateSuccess(true)

      console.log('after set create success')
    }
  }, [updateItemResponse, error, isPending, updatedItem, item.ItemCode, refreshAdminAuctionQuery, updateSuccess])

  //use effect to enable submit button when all fields are filled
  useEffect(() => {  
    if (title && description && value && startingBid && minRaise && donor && itemCode) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }

  }, [title, description, value, startingBid, minRaise, donor, itemCode])

  const toggleShowEditImage = () => {
    let toggled = !showEditImage
    setShowEditImage(toggled)
  }

  if (updateSuccess) {
    return (
      <>
        <div className={css['success-container']}>
          <img className={css['success-checkmark']} src={checkmark} alt={'Payment Successful'}/>
          <p>Item updated successfully!</p>
        </div>
      </>
    )
  }
  
  if (showEditImage) {
    return (
      <div className={css['page-container']}>
        {!isImageSelected && !isImageUploaded &&
          <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=>toggleShowEditImage()} >Back to Item Edit</button>}

        {isImageUploaded &&
          <div className={css['success-container']}>
            <img className={css['success-checkmark']} src={checkmark} alt={'Item pic uploaded successfully!'}/>
            <p id="imageUploadSuccessMessage">Your image has been uploaded successfully!</p>
          </div>}

        <UploadFiles imageType={'item'} auction={auction} item={item} refreshQuery={refreshAdminAuctionQuery} parentSubmitSuccess={imageUploadResult} setIsImageSelected={setIsImageSelected}/>
      </div>
    )
  }

  return (
    <div className={css['page-container']}>
      <form onSubmit={onSubmit}>
        <h2>Edit Item "{item.Title}" for {auction.Title}</h2>
        <p className={css['label-notice']}>All fields are required to create an item.</p>
        <div className={`${css['item-container']}`} >
          <div className={css['create-item-input']}>
            <label>Title</label>
            <input id="itemTitleInput"
              type='text'
              placeholder='e.g. Tickets to L.A. Opera'
              value={title}
              onChange={(e) => setTitle(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Description</label>
            <label> - for weblinks use this format: <b>[link text](https://example.com)</b></label>
            <label> - for italics use this format: <b>{italicLabel}</b></label>
              <textarea id="itemDescriptionInput"
                placeholder='e.g. Description of item goes here ...'
                value={description}
                onChange={(e) => setDescription(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Fair Market Value (in dollars)</label>
              <input id="itemFairMarketValueInput"
                type='number'
                placeholder='e.g. 120'
                min='1'
                value={value}
                onChange={(e) => setValue(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Starting Bid (in dollars)</label>
              <input id="itemStartingBidInput"
                type='number'
                placeholder='e.g. 50'
                min='1'
                value={startingBid}
                onChange={(e) => setStartingBid(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Minimum Raise (in dollars)</label>
              <input id="itemMinRaiseInput"
                type='number'
                placeholder='e.g. 5'
                min='1'
                value={minRaise}
                onChange={(e) => setMinRaise(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Item Number <span className={css['label-notice']}>(cannot be edited after initialization)</span>
            </label>
              <input id="itemCodeInput"
                type='number'
                placeholder='e.g. 5471'
                min='1'
                value={itemCode}
                disabled={true}
                onChange={(e) => setItemCode(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Donor</label>
              <input id="itemDonorInput"
                type='text'
                placeholder='e.g. UCLA Athletics'
                value={donor}
                onChange={(e) => setDonor(e.target.value)}/>
          </div>
          <div className={css['create-item-input']}>
            <label>Item Image Upload <span className={css['acceptable-text']}>(Accepted file types: .jpg, .jpeg, .png, .avif) </span></label>
            <p>Current filename: {imageFilename}</p>
              <div className={css['thumbnail-container']}>
                <img src={imageUrl} alt={`${item?.Title} thumbnail`} />
                <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['button-image-edit']}`} onClick={()=>toggleShowEditImage()} >Edit Image</button>
              </div>
            <p>(Image to be uploaded on next page)</p>
          </div>
        </div>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`}
          disabled={disableSubmit || disableEdit} id="saveItemSubmit" type="submit" >Save Item</button>
      </form>
    </div>
  )
}