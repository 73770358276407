import { formatMoneyCents } from 'services/util'

import css from '../../components/sass/confirmmodal.module.scss'

export default function NumberRow({number, dollar, text}) {

  return (
    <div className={css['number-row']}>
      <h4>{`${dollar ? `$${formatMoneyCents(number)}` : number}`}</h4>
      <p>{text}</p>
    </div>
  )
}