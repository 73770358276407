import { getItemUserStatus } from '../components/utils'
import css from './sass/itembid.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import { formatMoney } from 'services/util'

export default function ItemBidInfo({ item, auctionState, currentUserId, auction }) {

  const itemUserStatus = getItemUserStatus(auction, item, currentUserId)
  
  const currentBidText = () => {
    if (auctionState === 'ended') return 'Winning Bid'
    if (item.CurrentBid) return 'Current Bid'
    return 'Starting Bid'
  }

  return (
    <div className={css['high-bid-container']}>
      <div className={css['high-bid-header']}>
        <div>
          <p className={`${css['bid-text']} `}>
            {currentBidText()}
          </p>
          <p className={`${css['bid-text-large']} `}>${formatMoney(item.CurrentBid ? item.CurrentBid : item.StartingBid)}</p>
        </div>
        <p className={itemUserStatus === 'winning' ? themeCss['high-bid-notification'] : themeCss['hidden']}>
          {auctionState === 'ended' ?
          'You have won this item!'
          : 'You are the highest bidder'}
        </p>
        <p className={itemUserStatus === 'outbid' ? themeCss['outbid-notification'] : themeCss['hidden']}>
          You have been outbid
        </p>

      </div>

      <div className={css['bid-info-box']}>
        { auctionState === 'started' && <p>{item.BidCount} {item.BidCount === 1 ? 'bid' : 'bids'}</p>}
        <p>Min. Raise: ${formatMoney(item.MinRaise)}</p>
        <p>Fair Market Value: ${formatMoney(item.Value)}</p>
      </div>
    </div>
  )
}