import ContactInfo from './FAQDescriptions/ContactInfo'
import TaxIdNumber from './FAQDescriptions/TaxIdNumber'
import GiftsReceivedDelay from './FAQDescriptions/GiftsReceivedDelay'
import MakeANewGift from './FAQDescriptions/MakeANewGift'
import SetUpApp from './FAQDescriptions/SetUpApp'
import ReceiptMissing from './FAQDescriptions/ReceiptMissing'
import PledgePayment from './FAQDescriptions/PledgePayment'
import WoodenAthleticFund from './FAQDescriptions/WoodenAthleticFund'
import TaxAndOtherInfo from './FAQDescriptions/TaxAndOtherInfo'
import MatchingGifts from './FAQDescriptions/MatchingGifts'
import TaxDocumentation from './FAQDescriptions/TaxDocumentation'
import DisclosureStatements from './FAQDescriptions/DisclosureStatements'
import GivingFromAbroad from './FAQDescriptions/GivingFromAbroad'

const responseTime = "We will contact you within one to three business days."

const privacy = `Your privacy is important to UCLA, and the registration process will require you to confirm your identity.
When registering, you will be asked to provide an email address or cell phone number,
after which you will receive an email or text message with information that will enable you to complete the process.`

const login = `After you register, you can log in using the email or cell phone and password you used to create your account.
You can also choose to log in through an existing Facebook or Google account.`

// f = fragment, used after # sign in url for deeplinking
export const faqs = [
  {q: "UCLA and The UCLA Foundation’s Disclosure Statements for Donors",f: 'disclosure', a: <DisclosureStatements/>},
  {q: "How do I contact UCLA Donor Relations?",                         f: 'contact',    a: <ContactInfo/>},
  {q: "When can I expect a response?",                                  f: 'response',   a: responseTime},
  {q: "What is UCLA’s Tax ID number?",                                  f: 'taxid',      a: <TaxIdNumber/>},
  {q: "How do I know if UCLA has received my gift?",                    f: 'received',   a: <GiftsReceivedDelay/>},
  {q: "How do I make a new gift?",                                      f: 'gift',       a: <MakeANewGift/>},
  {q: "How do I set-up or adjust an Automatic Pledge Payment (APP)?",   f: 'app',        a: <SetUpApp/>},
  {q: "Why can’t I see a receipt for my donation?",                     f: 'receipt',    a: <ReceiptMissing/>},
  {q: "How do I make a pledge payment via My Giving Gateway?",          f: 'pledge',     a: <PledgePayment/>},
  {q: "How do I register for My Giving Gateway?",                       f: 'register',   a: privacy},
  {q: "How do I log in to My Giving Gateway?",                          f: 'login',      a: login},
  {q: "Giving to Wooden Athletic Fund",                                 f: 'wooden',     a: <WoodenAthleticFund/>},
  {q: "Important Tax and Other Information",                            f: 'taxinfo',    a: <TaxAndOtherInfo/>},
  {q: "UCLA and the UCLA Foundation Documentation",                     f: 'docs',       a: <TaxDocumentation/>},
  {q: "How can I find out if my company has a matching gifts program?", f: 'matching',   a: <MatchingGifts/>},
  {q: "How can I make a gift from abroad?",                             f: 'abroad',     a: <GivingFromAbroad/>},
]