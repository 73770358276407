import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../api'
import { deleteAuctionItemMutation } from '../graphql/Mutations'

import css from './sass/delete.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function ItemDeleteButton({ item, refreshAdminAuctionQuery, style }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const {data: deleteItemResponse, error, isPending, mutate: deleteItem } = useMutation({
    mutationFn: (input) => {
      return graphql({query: deleteAuctionItemMutation, variables: {itemId: input} })
    },
  })

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Item not deleted: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (deleteItemResponse) {
      console.log(deleteItemResponse)
      console.log('Item deleted: ', deleteItemResponse.data.deleteAuctionItem.ItemId)
      refreshAdminAuctionQuery()
      showDeleteAuctionModal()
    }
  }, [deleteItemResponse, error, isPending])

  const showDeleteAuctionModal = () => {
    let newValue = !showDeleteModal
    setShowDeleteModal(newValue)
  }
  
  let buttonClass = `${themeCss['FilledYellow']} ${themeCss['button']}`
  let buttonText = 'Delete'

  if (style === 'text') buttonClass = `${css['delete-button-text']}`
  
  return (
    <>
      <button title="Delete" className={buttonClass} 
        onClick={()=> showDeleteAuctionModal()}>
        {buttonText}
      </button>

      {showDeleteModal && 
        <>
          <div className={themeCss['modal-background']} onClick={()=>showDeleteAuctionModal()}> </div>
            <div className={css['delete-modal']}>
              <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=>showDeleteAuctionModal()} >Close Modal</button>
              <p>
                Are you sure you want to delete {item.Title}?
              </p>
              <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${themeCss['centered-button']}`}
                onClick={()=> deleteItem(item.ItemId)}>Confirm Delete</button>
            </div>
        </>
      }
    </>
  )
}