import { useState, useEffect } from 'react'

import { hourTimer } from '../util/dateString'

import AuctionStatus from '../components/AuctionStatus'

import css from './sass/auctionscards.module.scss'

//took userAttending out of props, commented out code snippets below, in case things change again in the future
export default function AuctionCountdown( { auctionDetail, isCheckedIn, auctionState, isBrowse } ) {

  const [countdown, setCountdown] = useState('')

  const updateCountdown = (timeString) => {
    setCountdown(timeString)
  }

  return (
    <>
      <AuctionStatus auction={auctionDetail} updateCountdown={updateCountdown} />
      {(auctionState === 'before' && (isBrowse === true || isCheckedIn === true)) && (
        <div className={css['box-left-yellow-accent-content']}>
          <p className={css['countdown-heading']}>Auction Begins Soon</p>
          <p className={css['countdown-timer']}>{countdown}</p>
        </div>)}

      {auctionState === 'started' && (
        <div className={css['box-left-yellow-accent-content']}>
          <p className={css['countdown-heading']}>Auction is live</p>
          <p className={css['countdown-timer']}>{countdown}</p>
        </div>)}

      {auctionState === 'ended' && (
        <div className={css['box-left-yellow-accent-content']}>
          <p className={css['countdown-over']}>Auction has ended.</p>
        </div>)}
    </>
  )
}