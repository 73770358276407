 import { API } from 'aws-amplify'

import store from 'redux/store'
import { doApi } from 'services/api'
import { resetLoginTimeout } from 'services/util';

import { listAuctionsQuery, getAuctionByIdQuery, getAuctionUserQuery, getAuctionByCodeQuery } from './graphql/Queries'
import { createUserMutation, updateUserMutation, registerForAuctionMutation, unregisterFromAuctionMutation, updateUserRegistrationMutation, updateUserFavoritesMutation } from './graphql/Mutations'
import { setAuctionUser } from './redux/actions'
import { getAuctionUser } from './redux/selectors'

API.configure({
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AUCTIONS_APPSYNC_HOST,
  // "aws_appsync_graphqlEndpoint": "https://6dbbpqxk5bgmjnd6q6e7mucqba.appsync-api.us-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-west-2",
  // "aws_appsync_authenticationType": "API_KEY",
  // "aws_appsync_apiKey": "da2-gep6wqtz3jgndmup5ndujw6sl4",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
})

export const graphql = async (args) => {
  resetLoginTimeout();
  return await API.graphql(args)
}

export const subscribe = (args, actions) => {

  return API.graphql(args).subscribe(actions)
}

export const registerUserForAuction = async (auctionCode, textAlertNumber, isPreview) => {
  const auction = await getAuctionByCode(auctionCode)

  const userId = isPreview ? "{PreviewUserId}" : "{UserIdFromToken}"

  const signUpResponse = await graphql({query: registerForAuctionMutation, variables: {userId, auctionId: auction.AuctionId, textAlertNumber: textAlertNumber || ""}})

  if (signUpResponse?.data && !signUpResponse?.errors && !isPreview) {
    // refresh auction user
    await getAuctionUserApi()
  }

  return signUpResponse
}
export const unregisterUser = async (auction, auctionUser) => {
  const unregisterResponse = await graphql({query: unregisterFromAuctionMutation, variables: {registrationId: auction.RegistrationId}})

  if (unregisterResponse?.data && !unregisterResponse?.errors) {
    // refresh auction user
    await getAuctionUserApi()
  }
}

export const updateUserRegistration = async (auction, auctionUser, properties) => {
  const updateUserRegistrationResponse = await graphql({query: updateUserRegistrationMutation, variables: {auctionId: auction.AuctionId, ...properties}})

  if (updateUserRegistrationResponse?.data && !updateUserRegistrationResponse?.errors) {
    // refresh auction user
    await getAuctionUserApi()
  }
}

export const updateUserFavorites = async (auctionUser, auctionId, itemId, add) => {
  const updateUserFavoritesResponse = await graphql({query: updateUserFavoritesMutation, variables: {auctionId, itemId, add}})

  if (updateUserFavoritesResponse?.data && !updateUserFavoritesResponse?.errors) {
    // refresh auction user
    await getAuctionUserApi()
  }

  return updateUserFavoritesResponse
}

// lists all auctions with EndTime after now
export const listUpcomingAuctions = async () => {
  console.log('listUpcomingAuctions!')

  return await listAuctions(new Date().toISOString())
}

// if endTimeAfter is blank, retrieves all auctions
const listAuctions = async (endTimeAfter) => {
  console.log('listAuctions!')

  const endTime = endTimeAfter || "2023-01-01T01:00:00.000Z"

  const query = localStorage.isSeleniumTest ? listAuctionsQuery.replace('EndTime', 'IsForTestOnly: {eq: true}, EndTime') : listAuctionsQuery

  const response = await graphql({query, variables: {endTime}})
  if (!response?.data?.listAuctions?.items) {
    console.error("error: ", response)
  }
  else {
    const auctionList = response.data.listAuctions.items
    // store.dispatch(setUpcomingAuctionList({auctionList}))
    // auctionList.forEach(auction => store.dispatch(setSavedAuction({auction})))
    // localStorage.setItem('auctionList', JSON.stringify(auctionList))
    return auctionList
  }
}

// retrieves items and full details for a given auction, saves details to local storage if not already saved
export const getAuctionByCode = async (auctionCode) => {
  console.log('getAuctionByCode!')

  const response = await graphql({query: getAuctionByCodeQuery, variables: {auctionCode}})
  if (!response?.data?.getAuctionByCode) {
    console.log(response)
    return({"error": response})
  }
  else {
    const auction = response.data.getAuctionByCode

    // spoof end and start time for preview mode
    if (sessionStorage.previewAuctionCode) {
      auction.StartTime = sessionStorage.previewStartTime || auction.StartTime
      auction.EndTime = sessionStorage.previewEndTime || auction.EndTime
    }

    return response.data.getAuctionByCode
  }
}

// goes outside of AppSync and GraphQL
export const getPublicAuctionDetail = async (auctionCode, publicId, email) => {
  console.log('getAuctionByCode!')
  return await doApi({noAuth: true, route:`nonauthauctiondetails?code=${auctionCode}&pid=${publicId}&email=${email}`})
}

// always goes to the DB for fresh auction data
export const getLiveAuctionData = async (auctionId) => {
  console.log('getAuctionDetail!')

  const response = await graphql({query: getAuctionByIdQuery, variables: {auctionId}})
  if (!response?.data?.getAuction) {
    return({"error": response})
  }
  else {
    return response.data.getAuction
  }
}

// retrieves user with all auctions user is signed up for (should be called after login)
export const getAuctionUserApi = async () => {
  console.log('getAuctionUserApi!')
  try {
    const response = await graphql({query: getAuctionUserQuery})
    if (response?.data?.getUser === null) {
      console.log("No user found: ", response)
      return null
    }
    else if (!response?.data?.getUser) {
      console.error("bad response in getAuctionUserQuery: ", response)
      return {error: {msg: "bad response in getAuctionUserQuery: " + JSON.stringify(response)}}
    }
    else {
      const auctionUser = response.data.getUser
      console.log({auctionUser})

      // filter only items user has won or is winning
      auctionUser.MyAuctions.forEach(auction => auction.Auction.Items = auction.Auction.Items.filter(item => item.CurrentHighBidder === auctionUser.UserId))

      store.dispatch(setAuctionUser({auctionUser}))
      return auctionUser
    }
  }
  catch (e) {
    console.error("error in getAuctionUserQuery", e)
    const msg = (e.errors && e.errors.length > 0) ? e.errors[0].message : e
    return {error: {msg: "error in getAuctionUserQuery: " + msg}}
  }
}

// Creates Auction User with new entries in auctions-cognito-login-mapping and auctions-users
export const createAuctionUser = async () => {
  console.log('createAuctionUser!')
    try {
      const response = await graphql({query: createUserMutation})
      if (!response?.data?.createUser?.UserId) {
        console.error("User not created: ", response)
      }
      else {
        const auctionUser = {UserId: response.data.createUser.UserId, MyAuctions: []}
        store.dispatch(setAuctionUser({auctionUser}))
        return auctionUser
      }
    }
    catch (e) {
      console.error("error in createAuctionUser:", e)
    }
}

// Creates Auction User with new entries in auctions-cognito-login-mapping and auctions-users
export const updateAuctionUser = async (userFields) => {
  console.log('updateAuctionUser!')
    try {
      const response = await graphql({query: updateUserMutation, variables: userFields })
      if (!response?.data?.updateUser?.UserId) {
        console.error("User not updated: ", response)
        return {error: {response}}
      }
      else {
        const updatedUser = response.data.updateUser
        const savedUser = getAuctionUser(store.getState())
        Object.assign(savedUser, updatedUser) // TODO - this probably isn't robust enough
        store.dispatch(setAuctionUser({auctionUser: savedUser}))
        return savedUser
      }
    }
    catch (e) {
      console.error("error in updateAuctionUser:", e)
      return {error: {e}}
    }
}