import { useCallback, useEffect } from 'react'

import infoIcon from '../../components/images/info-icon-orange.svg'
import { handleEscapeKey } from 'sites/auctions/components/utils'

import css from '../../components/sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function ConfirmStatusUpdate({setShowConfirmModal, saveAuction, input, buttonText, paragraphText, headerText}) {


  const toggleModal = useCallback(() => {
      setShowConfirmModal(false)
    }, [setShowConfirmModal])
  
    useEffect(() => {
      const onKeyDown = (event) => handleEscapeKey(event, toggleModal)
      //add event listener for when modal is mounted
      document.addEventListener('keydown', onKeyDown)
      //clean up when modal is unmounted
      return () => document.removeEventListener('keydown', onKeyDown)
  
    }, [toggleModal])


  return (
    <div className={css['modal-container']}>
      <div className={themeCss['modal-background']} onClick={()=>toggleModal()}> </div>
      <div className={`${css['modal-content']} ${css['top-orange-border']}`}>
  
        <div className={css['simple-body']}>
          <img src={infoIcon} />
          <div className={css['simple-text']}>
            <h3>{headerText}</h3>
            <p>{paragraphText}</p>
          </div> 
        </div>
        
        {/* {data.errorMessage && <p>Error</p>} */}
        
        <div className={css['button-options']}>
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${themeCss['centered-button']}`}
              onClick={()=>toggleModal()} >Cancel</button>
          
          <button className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> saveAuction(input)}>{buttonText}</button>
        </div>
      </div>    
    </div>
  )
}