// the account information section in the alumni overview page
import { doLink } from 'services/util'
import css from '../sass/profile.module.scss'
import { FilledBlue } from 'css/Buttons'
function Profile({
  primaryName,
  attributes,
  constituentLookupId,
  education,
  username,
  businessEmail,
  homeEmail
}) {
  const {
    firstName,
    keyName,
  } = primaryName

  const degreeList = education.map(({degree, major, classOf}, idx) => {
    return (<li key={idx}>{ degree } { major } {classOf ? `’${classOf.toString().slice(-2)}` : ''}</li>)
  })

  let displayUsername = attributes.email || attributes.phone_number

  // For Browse As User - since we don't have the username - only CRM ID
  // it's not perfect but I think any other solution will confuse the help desk people even more
  // may revisit and allow look up Browse As User by cognito username instead of CRM ID
  if (sessionStorage.altCrmId) {
    displayUsername = (businessEmail && businessEmail.isPrimary) ? businessEmail.emailAddress : homeEmail ? homeEmail.emailAddress : ''
  }

  return (
    <section>
      <div className={css.container}>
        <div className={css.cell}>
          <div>
            <em className="label">Name</em>
            <span>{firstName} {keyName}</span>
          </div>
        </div>
        <div className={css.cell}>
          <div>
            <em className="label">Username</em>
            <span>{displayUsername}</span>
          </div>
        </div>
        { degreeList.length > 0 && (
          <div className={css.cell}>
            <div>
              <em className="label">Degree{ degreeList.length > 1? 's' : ''}</em>
              <ul className={css['degree-list']}>
                { degreeList }
              </ul>
            </div>
          </div>
        )}
        <div className={css.cell}>
          <div>
            <em className="label">Portal Account ID</em>
            <span>{constituentLookupId}</span>
          </div>
        </div>
      </div>
      <div className={css.update}>
        <p>Update your mailing address, phone numbers or email address.</p>
        <FilledBlue isSmall={true} onClick={ e => doLink('/profile') }>Update Profile</FilledBlue>
        { !attributes.identities && (
          <FilledBlue isSmall={true} onClick={ e => doLink('/resetpasswordstart', { currentUsername:  username }) }>Change Password</FilledBlue>
        )}
      </div>
    </section>
  )
}

export default Profile
