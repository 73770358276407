import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import { ThemeContext } from 'ThemeContext';
import { handleSignin } from "../signin"
import { redirectSignedInUser } from "../helper"
import { signOut } from "../signout";
import { phoneMedia } from 'common/DeviceDetect'
import { showLoading } from 'services/util'
import { history } from 'services/history.js'

import EmailPhoneToggle from '../components/EmailPhoneToggle'
import { Heading } from '../components/Heading'
import { Label } from '../components/Label'
import { Button } from '../components/Buttons'
import { SignInRegisterDiv } from '../components/SignInRegisterDiv'
import ExternalAccounts from '../components/ExternalAccounts'

import { Input } from 'css/forms'
import { ErrorSpan } from 'css/CommonStyle'
import orDividerImg from 'images/or.png';

import * as logon from '../css/AuthenticateStyles'

function LoginForm(props) {
  const theme = useContext(ThemeContext)
  const [currentUsername, setCurrentUsername] = useState(localStorage.getItem('savedUsername'))
  const [showForm, setShowForm] = useState(true)
  const [showSocialButtons, setShowSocialButtons] = useState(true)
  const [genericError, setGenericError] = useState('')
  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const savedUsername = localStorage.getItem('savedUsername')
  const isPhone = phoneMedia.matches;

  useEffect(() => {
    async function doInit() {

      // Amplify v5 automatically grabs the access_token, logs the user in, then redirects to /postlogin.
      // But it doesn't trigger a react page load, so we do it here.
      if (window.location.href.indexOf('/postlogin') > 0) {
        console.log('Login.js - componentDidMount - social logon finish')
        showLoading(true, "Login.js - componentDidMount - social logon finish")
        history.push('postlogin')
      }
      else {
        const urlParams = new URLSearchParams(window.location.search)
        const ssoRedirectUrl = urlParams.get('redirect_url')

        if (localStorage.loggedInUser
            && (theme.forceLogoutOnSignOrRegister
                || (ssoRedirectUrl && theme.forceLogoutOnRedirect && theme.redirectTest(ssoRedirectUrl)) ) ) {
          await signOut(window.location.href, ssoRedirectUrl)
        }
        else {
          // directs user to landing page if they're signed in
          if (redirectSignedInUser()) {
            return;
          }
        }

        console.log("ssoRedirectUrl: " + ssoRedirectUrl)
        if (ssoRedirectUrl && theme.redirectTest)
          console.log(theme.redirectTest(ssoRedirectUrl))

        if (ssoRedirectUrl && theme.redirectTest && theme.redirectTest(ssoRedirectUrl))
          sessionStorage.setItem('ssoRedirectUrl', ssoRedirectUrl)

        handleResize()
        phoneMedia.addListener(handleResize)
      }
    }
    doInit()

    return () => {
      phoneMedia.removeListener(handleResize)
    }
  }, [theme])

  const setMessages = {
    setGenericError,
    setUsernameError,
    setPasswordError,
  }

  const handleResize = () => {
    const isPhone = phoneMedia.matches
    setShowSocialButtons(isPhone ? false : true)
    if (!isPhone) {
      setShowForm(true)
      setShowSocialButtons(true)
    }
  }

  const showSocialButtonsClick = () => {
    setShowForm(false)
    setShowSocialButtons(true)
  };

  const showFormClick = () => {
    setShowForm(true)
    setShowSocialButtons(false)
  };

  const handleSubmit = async (event) => {
    await handleSignin(event, setMessages)
  }

  const updateUsername = username => {
    setCurrentUsername(username)
  }

  return (
    <div>
      <logon.Content>
        <h3 className="bold center h4-mobile" id="authPageTitle">Sign In</h3>

        {isPhone && <Heading>{showForm ?
          <p><span className="block-title bold">{theme.signinText}</span></p> :
          <p>You may also sign in through an external account.</p>}
        </Heading>}

        <logon.HeaderForSignInRegisterForm>
          <p className={(showForm ? 'show' : '')} onClick={showFormClick} >Email/Phone</p>
          <p className={(showSocialButtons ? 'show' : '')} onClick={showSocialButtonsClick}>External Account</p>
        </logon.HeaderForSignInRegisterForm>

        <logon.ContentInner>
          {showForm &&
            <logon.SignInForm onSubmit={handleSubmit}>

              <SignInRegisterDiv>
              {!isPhone&&<div><span className="block-title bold">{theme.signinText}</span></div>}

                <EmailPhoneToggle width="319px"
                  bgColor={"#f6f6f6"}
                  savedUsername={savedUsername}
                  handleChange={updateUsername}
                  usernameError={usernameError} signIn />

                <logon.SignInRegisterInnerDiv>
                  <Label>Password</Label>
                  <Input bgColor="#f6f6f6" type="password" name="password"
                    className={(passwordError ? "error" : "")}
                  />
                  {passwordError &&
                    <ErrorSpan textAlign="right">
                      <label>{passwordError}</label>
                    </ErrorSpan>}
                </logon.SignInRegisterInnerDiv>

                {genericError &&
                  <logon.SignInRegisterInnerDiv>
                    <ErrorSpan textAlign="right">
                      <label>{genericError}</label>
                    </ErrorSpan>
                  </logon.SignInRegisterInnerDiv>}

                  <logon.SignInRegisterInnerDiv >
                    <logon.SigInInnerDiv>
                      <input type="checkbox" name="rememberMe" id="rememberme" defaultChecked={!!savedUsername} />
                      <Label id="rememberMeLabel" htmlFor="rememberme"> Remember Me</Label>
                      <Link
                        id="forgotPasswordLink"
                        to={{
                          pathname: "/resetpasswordstart",
                          state: {currentUsername: currentUsername}
                        }}>
                          Forgot Password?
                      </Link>
                    </logon.SigInInnerDiv>
                  </logon.SignInRegisterInnerDiv>

                <logon.SignInRegisterInnerDiv>
                  <Button type="submit">SIGN IN</Button>
                  <p>Don't have an account? <Link to='/registration'>Register</Link> now</p>
                </logon.SignInRegisterInnerDiv>

              </SignInRegisterDiv>
            </logon.SignInForm>}

          {!isPhone &&
            <logon.SignInDivider >
              <img alt='' src={orDividerImg} />
            </logon.SignInDivider>}

          {showSocialButtons &&
            <ExternalAccounts
              isPhone={isPhone}
              socialtitle="You may also sign in through an external account."
              isRegistration={false}
            />}
        </logon.ContentInner>
      </logon.Content>
    </div>
  )
}

export default LoginForm
