import { useCallback, useEffect } from 'react'
import { showLoading } from 'services/util'

import { sendAllPaymentReminderEmails } from '../auctionsadminutil'
import { handleEscapeKey } from 'sites/auctions/components/utils'

import css from '../../components/sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

// TO DO : delete after confirm modals are all broken out into individuals.
export default function ConfirmSendPayReminders({setShowConfirmModal, data}) {

  const toggleModal = useCallback(() => {
      setShowConfirmModal(false)
    }, [setShowConfirmModal])
  
  useEffect(() => {
    const onKeyDown = (event) => handleEscapeKey(event, toggleModal)
    //add event listener for when modal is mounted
    document.addEventListener('keydown', onKeyDown)
    //clean up when modal is unmounted
    return () => document.removeEventListener('keydown', onKeyDown)

  }, [toggleModal])

  const performConfirm = async () => {
        // case 'paymentReminderEmail':
            console.log('send out payment reminder email action')

            showLoading(true)
    const response = await sendAllPaymentReminderEmails(data.auctionId)
    showLoading(false)
    console.log({response})
    setShowConfirmModal(false)
  }

  return (
    <div className={css['modal-container']}>
      <div className={themeCss['modal-background']} onClick={()=>toggleModal()}> </div>
      <div className={`${css['modal-content']} `}>

        <div className={css['withdata-body']}>
          <div className={css['withdata-text']}>
            <h3>SEND PAYMENT REMINDER EMAIL</h3>
              <p>Send payment reminders to all attendees who have not paid for their items.</p>
          </div> 
        </div>

        {/* {data.errorMessage && <p>Error</p>} */}

        <div className={css['button-options']}>
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${themeCss['centered-button']}`}
              onClick={()=>toggleModal()} >Cancel</button>
          
          <button className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> performConfirm()}>Send Reminder Email(s)</button>
        </div>
      </div>    
    </div>
  )
}