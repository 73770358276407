import { useEffect, useState} from 'react'
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { subscribe, getLiveAuctionData, getAuctionByCode } from '../../api'
import { onUpdateCurrentBidSubscription } from '../../graphql/Subscriptions'

import css from './sass/liveauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

import LiveBiddingItemsTable from './LiveBiddingItemsTable'
import LiveBiddingBiddersTable from './LiveBiddingBiddersTable'

export default function LiveAuctionView(props) {
  
  const [auctionDetail, setAuctionDetail] = useState()
  const [auctionId, setAuctionId] = useState()
  const [users, setUsers] = useState()
  const [totals, setTotals] = useState({bidCount: 0, totalAmountBid: 0})
  const [userFullNamesMap, setUserFullNamesMap] = useState({})
  const [loadError, setLoadError] = useState()
  const [auctionCode, setAuctionCode] = useState(props.auctionCode)

  // get first load of auction data by code, so it will work for deep-linking
  const { data, error, isLoading } = useQuery({
    queryKey: ["liveAuctionDetail", auctionCode],
    queryFn: async () => await getAuctionByCode(auctionCode),
    staleTime: 0
  })

  useEffect(() => {
    showLoading(isLoading)

    if (error || (data && data.error)) {
      console.log('Error!', error || data.error)
      setLoadError(data.error)
    }
    else if (data) {
      console.log('use effect data setting into the new auction')
      setAuctionDetail(data)
      setAuctionId(data.AuctionId)
    }
  }, [data, error, isLoading])

  useEffect(() => {
    if (!auctionId) return

    const getLatestAuctionDetailForAllItems = async () => {
      setAuctionDetail(await getLiveAuctionData(auctionId))
    }

    const subCreateFiltered = subscribe({query: onUpdateCurrentBidSubscription, variables: {auctionId: auctionId}}, {
      next: (result) => {
        console.log('subscription result', result)
        getLatestAuctionDetailForAllItems()
      },
      error: (error) => {
        console.error("Subscription error!: ", error.error.errors[0].message)
      }
    })

    return () => {
      console.log('unsubscribing...')
      subCreateFiltered.unsubscribe()
    }
  }, [auctionId])

  useEffect(() => {
    if (auctionDetail && auctionDetail.Bids) {

      let bidCount = 0, totalAmountBid = 0
      auctionDetail.Items.forEach(item => {
        bidCount += item.BidCount
        totalAmountBid += item.CurrentBid
      })
      setTotals({bidCount, totalAmountBid})

      const currentlyWinningBids = {}
      const sortedUsers = auctionDetail.Bids.reverse().reduce((result, currentBid) => {
        const existingUserEntry = result.find((entry) => entry.UserId === currentBid.UserId)

        // only record winning bid amounts in total, high bid should always come first (may fail in weird race conditions, don't rely on)
        let totalBidAmount = existingUserEntry?.totalBidAmount || 0
        if (!currentlyWinningBids[currentBid.ItemId] || currentlyWinningBids[currentBid.ItemId] < currentBid.BidAmount) {
          totalBidAmount += currentBid.BidAmount
          currentlyWinningBids[currentBid.ItemId] = currentBid.BidAmount
        }

        if (existingUserEntry) {
          existingUserEntry.numberOfBids++
          existingUserEntry.FullName = currentBid.FullName
          existingUserEntry.totalBidAmount = totalBidAmount
        }
        else {
          result.push({
            UserId: currentBid.UserId,
            FullName: currentBid.FullName,
            numberOfBids: 1,
            totalBidAmount: totalBidAmount,
          })
        }

        return result
      }, []).sort()

      setUsers(sortedUsers)
    } else if (auctionDetail && auctionDetail.Bids === null) {
      setUsers([])
    }
  }, [auctionDetail])

  useEffect(() => {
    if (users) {
      const fullNames = {}
      for (const user of users) {
        fullNames[user.UserId] = user.FullName
      }
      setUserFullNamesMap(fullNames)
    }
  }, [users])

  if (loadError)
    return <p>Error: {JSON.stringify(loadError,0,2)}</p>

  if (!auctionCode)
    return (<h2>Auction Code Missing in URL</h2>)

  if (!auctionDetail)
    return (<></>)

  return (
    <div className={css['admin-container']}>

      <p><b>Total Number of Bids:</b> {totals.bidCount}</p>
      <p><b>Total Amount Bid:</b> ${totals.totalAmountBid}</p>

      <h3>Items</h3>
      <p className={themeCss['boldGreen']}>Items in bold green are above Fair Market Value:</p>
      <p>After an item receives a bid, you can click on the Bid Count to view the item's entire Bid History.</p>

      <LiveBiddingItemsTable auctionDetail={auctionDetail} userFullNamesMap={userFullNamesMap} />
      
      <h3>Bidders</h3>
      <p>After a user places a bid, you can click on the Full name to view the user's entire Bid History</p>
      <LiveBiddingBiddersTable users={users} auctionDetail={auctionDetail} />
      
    </div>
  )
}