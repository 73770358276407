import { useEffect } from "react"
import { useMutation } from "@tanstack/react-query"

import { showLoading } from "services/util"
import { graphql } from "../api"
import { history } from 'services/history.js'

import { updateSolicitationReviewMutation } from '../graphql/Mutations'

import css from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import PreviewPublishChecklist from "./PreviewPublishChecklist"

export default function SolicitationReview( { auctionDetail, refreshAdminAuctionQuery }) {
  console.log('auction', auctionDetail)

  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateSolicitationReviewMutation, variables: input })
    },
  })

  useEffect(() => {
    showLoading(isPending)
  }, [isPending])

  useEffect(() => {
    if (error) {
      console.log("Auction update error: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateAuctionResponse) {
      console.log(updateAuctionResponse)
      console.log('Auction updated: ', updateAuctionResponse?.data.updateSolicitationReview)
      refreshAdminAuctionQuery()
    }
  }, [updateAuctionResponse, error, refreshAdminAuctionQuery ])

  const updateReview = async (value) => {
    const input = {
      auctionId: auctionDetail.AuctionId,
      reviewStatus: value
    }
    saveAuction(input)
  }

  if (!auctionDetail) return <></>

  return (
    <div className={`${css['widget-container']} ${css['widget-solicitation']}`}>
      <div className={css['flex-centered-spaced-container']}>
        <h3>{auctionDetail.Title}</h3>

        <PreviewPublishChecklist liveStatus={'before'} auctionDetail={auctionDetail} location={'widget'} />
      </div>
      
      <div className={css['flex-centered-spaced-container']}>
        <button id={`srPreviewTestSite-${auctionDetail.AuctionCode}`} className={`${themeCss['button']} ${themeCss['FilledYellow']}`}
          onClick ={(e) => {history.push({
            pathname: '/auctionhome',
            search: `?isPreview=true&auctionId=${auctionDetail.AuctionCode}`,
        })}}>Preview Test Site</button>
      
        { auctionDetail.ReviewStatus === 'Approved'
          ? <button className={`${themeCss['button']} ${themeCss['StrokeRed']}`} onClick ={() => {updateReview('Removed')}}>Remove Solicitation Review</button>
          : <button className={`${themeCss['button']} ${themeCss['StrokeBlue']}`} onClick ={() => {updateReview('Approved')}}>Approve Solicitation Review</button>
    }
      </div>
    </div>
  )
}