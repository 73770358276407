import { Auth } from 'aws-amplify';
import { showLoading, resetLoginTimeout } from "services/util";
import { doSsoCookie, doSsoRedirect, doCrmUser } from "./helper";
import { history } from 'services/history'
import store from 'redux/store';
import { setLoggedInUser } from 'redux/actions';
import { getRedirectAfterLogin } from 'redux/selectors';

// handles post login logic to determine where to route successully logged in use (based on CRM user status)
export const doPostLogin = async (signinData) => {
  const theme = store.getState().theme

  const signinDestination = getRedirectAfterLogin(store.getState())
  const ssoRedirectUrl = sessionStorage.ssoRedirectUrl

  sessionStorage.clear();

  const cognitoUser = await Auth.currentAuthenticatedUser()

  let primaryCrmId = null;
  try {
    primaryCrmId = cognitoUser.signInUserSession.idToken.payload['custom:BlackBaudId'];
    const groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
    if (groups) {
      if (groups.indexOf("APAdminUsers") > -1) localStorage.setItem('isApAdmin', true);
      if (groups.indexOf("DPAdminUsers") > -1) localStorage.setItem('isDpAdmin', true);
      if (groups.indexOf("UCLA1AdminUsers") > -1) localStorage.setItem('isUcla1Admin', true);
      if (groups.indexOf("AuctionAdminUsers") > -1) localStorage.setItem('isAuctionAdmin', true);
      if (groups.indexOf("AuctionSuperAdminUsers") > -1) localStorage.setItem('isAuctionSuperAdmin', true);
      if (groups.indexOf("SolicitationReviewAdminUsers") > -1) localStorage.setItem('isSolicitationReviewAdmin', true);
    }
  }
  catch (e) { }

  resetLoginTimeout();

  if (!primaryCrmId && (!cognitoUser.attributes || cognitoUser.attributes.email !== 'dp.system.error.simulation@gmail.com')) {

    if (theme.setSsoCookieForNonCrmUser)
      await doSsoCookie(cognitoUser);

    const failMsg = cognitoUser.signInUserSession.idToken.payload['failMsg'];

    store.dispatch(setLoggedInUser({cognitoUser, crmUser:null}));

    if (theme.specificLoginFailTest && theme.specificLoginFailTest(failMsg)) {
      // do nothing
    }
    else if (failMsg.indexOf('SPOUSE_CRM_USERS_FOUND') === 0) {
      const userAttributes = cognitoUser.attributes || cognitoUser.signInUserSession.idToken.payload // if user is coming from openid they may not have attributes
      const loggedInUser = Object.assign({}, userAttributes);

      // social users won't have password, will be forced to login again
      if (signinData) {
        loggedInUser.username = signinData.username;
        loggedInUser.password = signinData.password;
      }
      theme.themeAnalytics.analyticsLoginConsolidate()
      history.push({pathname: '/multipleaccounts', state:{loggedInUser, users: failMsg.split('|').slice(1).map(u => u.split('~')) }});
    }
    else if (ssoRedirectUrl && !theme.captureNonCrmUser && theme.redirectTest && theme.redirectTest(ssoRedirectUrl)) {
      return await doSsoRedirect(ssoRedirectUrl);
    }
    else if (theme.closeAfterSignin && !theme.captureNonCrmUser && !ssoRedirectUrl) {
      // gives warning but doesn't throw excexption if window wasn't opened by script
      window.close()
      showLoading(false, "doPostLogin - non crm user");
      return
    }
    else {
      const themePathOverride = (theme.customPostLogin) ? await theme.customPostLogin({cognitoUser}) : ''

      theme.themeAnalytics.analyticsLoginNoHistory()

      let [pathname, search] = (themePathOverride || signinDestination || theme.nonCrmUserLandingPage || "/").split('?')
      if (search) search = '?' + search
      history.push({pathname, search, state:{failMsg}}); // have to split search into separate property, or page doesn't load
    }

    showLoading(false, "doPostLogin - non crm user");
  }
  else {
    await doCrmUser(signinDestination, cognitoUser, ssoRedirectUrl);
  }
};
