import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../api'
import { updateAuctionMutation } from '../graphql/Mutations'

import themeCss from '../components/sass/theme.module.scss'
import { toEndingString } from '../util/dateString'

import ConfirmStatusUpdate from './ConfirmModal/ConfirmStatusUpdate'
import { sendAllPaymentReminderEmails } from './auctionsadminutil'

export default function UpdateAuctionStatusButton({ auction, fromStatus, toStatus, refreshAdminAuctionQuery, needsSolicitation}) {

  const [showModal, setShowModal] = useState(false)
  const [buttonText, setButtonText] = useState('Update Status')
  const [headerText, setHeaderText] = useState('')
  const [paragraphText, setParagraphText] = useState('')
  const [triggerLoading, setTriggerLoading] = useState(false)

  //initialize it in the useState, if it's for ending the auction, it will update in the useEffect
  const [input, setInput] = useState({
    AuctionId: auction.AuctionId,
    Status: toStatus
  })
  
  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      setTriggerLoading(true)
      delete input.isEndNow
      return graphql({query: updateAuctionMutation, variables: {input: input} })
    },
  })

  const doSave = async (input) => {
    saveAuction(input)

    if (input.isEndNow) {
      // wait for auction to be updated in the DB
      setTimeout(async () => {
        const response = await sendAllPaymentReminderEmails(auction.AuctionId)
        // const responseData = JSON.parse(response.data.sendPaymentReminders.ResponseData)
        // console.log({responseData})
      }, 5000)
    }
  }

  useEffect(() => {

    // trigger loading was needed because in sister components (inside the tabs) the showLoading would get overriden by the update auction status button loading
    // set up an internal check to see if the component itself needed to show loading,
    // and only affect the showLoading util function if it was being caused by THIS component
    const performErrorCheck = () => {
      if (error) {
        console.log("Auction not created: ", error)
        console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
      }
      else if (updateAuctionResponse) {
        console.log(updateAuctionResponse)
        console.log('Auction updated: ', updateAuctionResponse.data.updateAuction.AuctionId)
        //invalidate query to refresh the page from AdminAuctionTabs.js
        refreshAdminAuctionQuery()
        setShowModal(false)
      }
    }

    if (triggerLoading) {
      showLoading(isPending)
  
      performErrorCheck()

      setTriggerLoading(false)
      showLoading(isPending)

    } else {
      performErrorCheck()
    }
  }, [updateAuctionResponse, error, isPending, refreshAdminAuctionQuery, triggerLoading])

  useEffect(() => {
    switch(fromStatus) {
      case "PREVIEW":
        setHeaderText('Are you sure you want to set this auction to Public?')
        setParagraphText('Making an event public will create an event in Event Hub, allow registrations, and it will be accessible by anyone with the link.')
        setButtonText('Make Auction Public')
        break;
      case "PUBLIC":
        if (toStatus === 'ENDED') {
          let now = new Date()
          setHeaderText('Are you sure you want to end live bidding?')
          setParagraphText('Bidding results will be saved as is. This action cannot be undone.')
          setInput({
            AuctionId: auction.AuctionId,
            EndTime: now.toISOString(),
            isEndNow: true,
          })
          setButtonText('End Bidding')
        }
        if (toStatus === 'CLOSED') {
          setHeaderText('Are you sure you want to close this auction site?')
          setParagraphText('Attendees will no longer be able to access the site for this auction. This action cannot be undone.')
          setButtonText('Close Auction')
        }
        if (toStatus === 'PREVIEW') {
          setHeaderText('Are you sure you want to set this auction to Preview?')
          setParagraphText('Once the auction is set to Preview, it is no longer be accessible by the public.')
          setButtonText('Set To Preview')
        }
        break;
      case "CLOSED":
        setHeaderText('TESTING ONLY')
        setParagraphText('Only for testing to move an auction to Public status. Do not do in production.')
        setButtonText('TESTING ONLY TO PUBLIC')
        break;
      default:
        setButtonText('Error Loading State')
    }
  }, [toStatus, fromStatus, auction.AuctionId])

  return (
    <>
      {(fromStatus !== "CLOSED" && toStatus !== "PREVIEW") && <button className={`${themeCss['FilledBlue']} ${themeCss['button']}
        ${buttonText === 'End Bidding' ? themeCss['StrokeRed'] : ''}`} onClick={() => setShowModal(true)}
        disabled={needsSolicitation === undefined ? false : needsSolicitation}>
        {buttonText}
      </button>}

      {showModal && 
        <ConfirmStatusUpdate setShowConfirmModal={setShowModal} saveAuction={doSave}
          headerText={headerText} paragraphText={paragraphText} buttonText={`Confirm ${buttonText}`} input={input} /> }
    </>
  )
}