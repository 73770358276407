import { history } from 'services/history'

import css from './sass/notification.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function BidSuccessActivateText( { auction } ) {
    
  return (
    <div className={css['successful-bid-textalert-container']}>
      <p>You can be notified via SMS when you are outbid on any item in this auction:</p>
      <button onClick={() => {history.push({ pathname: '/flow/phoneverification', auction }) }}
        className={`${themeCss['button']} ${themeCss['StrokeBlue']}`}>Activate SMS alerts</button>
    </div>
  )
}