import { SET_IS_LOADING } from "../actionTypes"

const initialState = false

const isLoading = (state = initialState, action) => {
  if(action.type === SET_IS_LOADING) {

    // uncomment to trace showLoading() calls
    // console.log(action.payload.isLoading, action.payload.srcTracker)

    const { isLoading } = action.payload
    return isLoading
  }
  return state
}

export default isLoading
