import styled from 'styled-components'
import { device } from 'common/Device'

const StyledBanner = styled.div`
  padding:1rem 3rem;
  font-size:${ 14/16 }rem;
  font-weight: bold;
  background-color: #FFD103;
  color: #000;
  text-align:center;

  a {
    display:inline-block;
    margin:0 0 0 0.2rem;
  }

  @media ${device.mobile} {
    font-size:0.8rem;
  }
`

const BannerWarning = props => (
  <StyledBanner>
    Our hearts go out to the members of our Bruin community who have been affected by the recent wildfires.
    Whether you need to update your address, modify an existing pledge payment schedule, or resolve any other
    concerns related to your giving, we are here to support you. If we can be of assistance, please don't hesitate
    to contact <a href="mailto:DonorRelations@support.ucla.edu">DonorRelations@support.ucla.edu</a> or 310-794-2447.
  </StyledBanner>
)

export default BannerWarning
