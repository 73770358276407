import { Component } from 'react';

import { getCrmUserAdmin } from "admin/adminapi";
import { doCrmUser } from "authentication/helper"
import { adminErrorHandler } from 'admin/adminutil'
import { showLoading } from 'services/util'
import store from 'redux/store';

import CRMProfile from 'admin/linkuser/CRMProfile'
import BlackbaudLink from 'admin/BlackbaudLink'
import Alert from 'common/notifications/Alert'

import styled from 'styled-components'
import * as colors from 'css/Colors';
import * as forms from 'css/forms';
import { primaryBlue } from 'css/Colors'
import { FilledYellow } from 'css/Buttons'
import iconInfo from 'images/iconColorinfo.svg'
import { FilledBlue } from 'css/Buttons';

const defaultFontSize = 16

const FormStyles = styled.div`
  box-sizing:content-box;
  form {
    padding: 20px;
    background-color: ${ primaryBlue };
    border-radius:${ 5/defaultFontSize }rem;
  }
  legend {
    display: block;
    color: #FFFFFF;
    padding-left: 0;
  }
  fieldset {
    display: block;
    border: none;
    margin: 0;
    padding: 0;
    & > div {
      display: flex;
      justify-content: stretch;
      align-items: center;
      .blackbaud-link {
        margin-bottom: 10px;
      }
    }
  }

  button[type="submit"] {
    margin-right:${ 25/defaultFontSize }rem;
  }

  .alert>div>div {
    padding-left: 0;
  }
`

const StyledClearableInput = styled(forms.ClearableInput)`
  min-width: ${ 60/defaultFontSize }rem;
  margin-right: ${ 10/defaultFontSize }rem;
  vertical-align: middle;
`

const SubmitButtons = styled.div`
  text-align: center;
  margin: ${30 / defaultFontSize}rem;
  & > div{
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
`

class BrowseAsUser extends Component {

  state = { };

  handleCrmSubmit = async e => {
    e.preventDefault()
    showLoading(true)

    const response = await getCrmUserAdmin(this.state.crmLookup);

    this.setState({
      crmLookupResponse: response,
    });

    if (response)
      this.setState({ isClean: false })
    else
      this.setState({ isClean: true })

    showLoading(false)
  }

  handleCrmChange = e => {
    this.setState({
      crmLookup: e.target.value,
      crmLookupResponse:''
    });
  }

  browseAsUser = async e => {
    e.preventDefault()
    showLoading(true);

    localStorage.removeItem('givingHistory')
    localStorage.removeItem('givingHistoryFlat')
    sessionStorage.setItem('proxyHeaderVisible', true)
    sessionStorage.setItem('altCrmId', this.state.crmLookupResponse.crmUser.constituentLookupId)

    await doCrmUser(store.getState().theme.crmUserLandingPage)

    showLoading(false)
  };

  render() {
    return (
      <FormStyles className="dashboard-container">
        <div className="hide-on-print">
          <h2>Browse As User</h2>
          <form onSubmit={this.handleCrmSubmit}>
            <fieldset>
              <legend id="">Enter CRM ID</legend>
              <div>
                <StyledClearableInput
                  type="text"
                  name="crmId"
                  errorOutline={this.state.crmLookupResponse && this.state.crmLookupResponse.error}
                  onChange={this.handleCrmChange}
                  placeholder="e.g. 0000171110"
                />
                <FilledYellow type="submit"> Search </FilledYellow>
                <BlackbaudLink />
              </div>
            </fieldset>
          </form>

          {this.state.crmLookupResponse && !this.state.crmLookupResponse.error &&
            <CRMProfile
              foundCRMUser={this.state.crmLookupResponse.crmUser}
              isBrowseAsUser={true}
            />}

          {this.state.crmLookupResponse && this.state.crmLookupResponse.error &&
            <Alert
              className="alert"
              nonPromoPage
              padded
              icon={iconInfo}
              color={colors.messagingRed}
              width="25" height="25" top="35%" >
              <div className="mismatch-error">{adminErrorHandler(this.state.crmLookupResponse.error, `No matching user with CRM ID "${this.state.crmLookup}". Please check the number and try again.`)}</div>
            </Alert>
          }

          {this.state.crmLookupResponse && this.state.crmLookupResponse.crmUser && !this.state.crmLookupResponse.error &&
            <SubmitButtons>
            <div>
              <FilledBlue name="linkUser"
              onClick={this.browseAsUser}>
                <span> BROWSE AS USER</span>
              </FilledBlue>
            </div>
            </SubmitButtons>}

        </div>
      </FormStyles>
    )
  }
}

export default BrowseAsUser;
