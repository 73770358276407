const oneHour = 1000 * 60 * 60
const oneDay = oneHour * 24

export const timeUntil = (currentTime, isoDateString) => {
  const timeInZone = new Date().toISOString()
  const auctionDate = isoDateString
  const timeDiff = auctionDate - timeInZone

  if (timeDiff < 0) return null
  else if (timeDiff <= oneHour) return 'withinHour'
  else if (timeDiff <= oneDay) return 'withinDay'

  return null
}

export const getAuctionStatus = (startTime, endTime) => {
  const timeInZone = new Date().toISOString()

  //take into account auctions still in preview state, they should be treated as not started
  if (startTime === null || endTime === null) return 'before'

  if (endTime <= timeInZone) return 'ended'
  if (startTime <= timeInZone) return 'started'
  else return 'before'
}

export const forceTimezone = (dateTimeString, timeZone) => {
  // create date in local timezone (will be wrong if not Pacific, which is ok)
  const localDateTime = new Date(dateTimeString)

  // get time in Pacific when localDate is happening in local time zone (which again, could be wrong)
  const dateTimeInTargetTimeZone = new Date(localDateTime.toLocaleString('en-US', { timeZone }));

  // find the hour difference between local time zone and target time zone
  const diff = localDateTime.getTime() - dateTimeInTargetTimeZone.getTime();

  const forcedTargetTime = new Date(localDateTime.getTime() + diff)

  return forcedTargetTime.toISOString();
}


//force time stored in database to Pacific for edit purposes
export const displayUtcInPacific = (dateTimeString) => {

  // Parse the input dateTimeString to create a Date object in UTC format
  const utcDate = new Date(dateTimeString)

  //options to extract time in Pacific Time Zone
  const options = {
    timeZone: 'America/Los_Angeles',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }

  // Convert the Date object to the Pacific Time Zone
  const pacificDateTimeString = utcDate.toLocaleString('en-US', options)
  // Extract the necessary parts from the Pacific Time Zone date
  const [month, day, year, hour, minute] = pacificDateTimeString.match(/\d+/g)
  // Construct the desired format 'YYYY-MM-DDTHH:mm'
  const formattedPacificDateTime = `${year}-${month}-${day}T${hour}:${minute}`
  return formattedPacificDateTime
}

export const displayUtcInPacificToUser = (dateTimeString) => {

  // Parse the input dateTimeString to create a Date object in UTC format
  const utcDate = new Date(dateTimeString)

  //options to extract time in Pacific Time Zone
  const options = {
    timeZone: 'America/Los_Angeles',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }

  // Convert the Date object to the Pacific Time Zone
  const pacificDateTimeString = utcDate.toLocaleString('en-US', options)
  return pacificDateTimeString
}

export const hourTimer = (currentTime, timerOver) => {
  const timeInZone = new Date(currentTime)  
  const endTimeDate = new Date(timerOver) - 0

  // console.log('time in zone ', timeInZone)
  // console.log('end time in ', new Date(timerOver))

  let timeDiff = endTimeDate - timeInZone

  //remainders after full hours are taken out
  const hourRemainder = timeDiff % (1000 * 60 * 60)

  // number of full hours left
  let numHours = (timeDiff - hourRemainder) / (1000 * 60 * 60)
  // if there are less than ten hours
  if (numHours < 10) numHours = `0${numHours}`
  
  //day logic

  //if there are more than 24 hours left
  if (numHours > 24) {
    //find the leftover hours after full days are taken out
    const dayRemainder = numHours % 24
    //find the total number of full days left
    const numDays = (numHours - dayRemainder) / 24

    //if there are less than ten hours left in the remainder
    if (dayRemainder < 10) {
      numHours = `${numDays} day${numDays>1?'s':''} 0${dayRemainder}`
    } else {
      numHours = `${numDays} day${numDays>1?'s':''} ${numHours - numDays*24}`
    }
  }

  const minRemainder = hourRemainder % (60 * 1000)
  let numMinutes = (hourRemainder - minRemainder) / (60*1000)
  if (numMinutes < 10) numMinutes = `0${numMinutes}`

  const secRemainder = minRemainder % 1000
  let numSeconds = (minRemainder - secRemainder) / 1000
  if (numSeconds < 10) numSeconds = `0${numSeconds}`

  let countdownString = `${numHours}:${numMinutes}:${numSeconds}`
  return countdownString.indexOf('NaN') > -1 ? '' : countdownString
}

export const isAuctionOver = (auction) => {
  const now = new Date()
  const endDate = new Date(auction.EndTime)
  return now > endDate
}