import { Auth } from 'aws-amplify'
import { showLoading } from "services/util"
import { doPostLogin } from "./postlogin"
import { validateSignin } from "./validators"
import { history } from 'services/history'
import store from 'redux/store';

// called by Login.js, RegistrationVerify.js and ResetPasswordFinish.js (user is auto-logged in at the end of those flows)
export const handleSignin = async (event, setMessages) => {
  const theme = store.getState().theme
  const analytics = theme.themeAnalytics

  let errorMessage = ""

  event.preventDefault()

  showLoading(true, 'handleSignin - start')

  const data = new FormData(event.target)

  const signinData = {
    username: data.get('username').trim(),
    password: data.get('password'),
    rememberMe: data.get('rememberMe'),
    usernameType: data.get('usernameType'),
  }

  // remove parens and dashes in phone number
  if (signinData.usernameType === 'phone') signinData.username = signinData.username.replace(/[(\-)]/g, '')

  analytics.analyticsInitStorage({
    loginMethod: signinData.usernameType,
    entryPath: 'signin',
    isInit: true,
  })

  errorMessage = validateSignin(signinData, setMessages)
  if (errorMessage.length !== 0) {
    showLoading(false, 'handleSignin - errorInValidateSignin')
    return
  }

  try {
    await Auth.signIn(signinData.username, signinData.password)

    await doPostLogin(signinData)

    if (signinData.rememberMe) localStorage.setItem('savedUsername', signinData.username)
    else localStorage.removeItem('savedUsername')
  }
  catch (err) {
    if (err.code === "UserNotConfirmedException") {
      showLoading(false, 'handleSignin - UserNotConfirmedException - registerVerify')
      history.push({pathname:'/registerVerify', state: {isFromSignIn: true, authParams: {username: signinData.username, password: signinData.password}}})
    }
    else if (err.code === "NotAuthorizedException") {
      showLoading(false, "handleSignin - NotAuthorizedException")
      setMessages.setGenericError("Incorrect " + (signinData.usernameType === 'phone' ? "mobile number" : "email") + " or password.")
    }
    else {
      showLoading(false, "handleSignin - generic error")
      setMessages.setGenericError(err.message)
    }

    analytics.analyticsFailedLogin(err.code)
  }
};

