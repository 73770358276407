import { useEffect, useState } from 'react'

import css from '../sass/adminitem.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

import { getImageUrl } from '../../util/images'
import ItemBidHistoryTable from '../UserDetail/ItemBidHistoryTable'
import UserBidHistoryTable from '../UserDetail/UserBidHistoryTable'


export default function ClosedItemDisplayRow( {item, auction} ) {

  const [imageUrl, setImageUrl] = useState()

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction, item])

    //formatting for user bid history table
  // for closed auctions the name will be clickable, so forcing the display as needed

  let formattedName = `${item.CurrentHighBidderInfo?.FirstName} ${item?.CurrentHighBidderInfo.LastName}`

  if (formattedName === 'null null') {
    formattedName = null
  }

  //formatting for user bid history table
  const user = {
    UserId: item?.CurrentHighBidder,
    FullName: formattedName,
  }

  return (
    <>
      <tr className={css['admin-item-row']}>
        <td>
          <img src={imageUrl} alt={item?.Title}/>
        </td>

        <td>{item?.Title}</td>
        <td>${item?.Value}</td>
        <td>${item?.StartingBid}</td>
        <td>${item?.MinRaise}</td>
        <td>{item?.ItemCode}</td>
        <td>{item?.Donor}</td>
        <td>{item.BidCount > 0 ? (<ItemBidHistoryTable auctionDetail={auction} item={item} />) : '0'}</td>
        <td className={item.CurrentBid > item.Value ? themeCss['boldGreen']: ''}>${item?.CurrentBid}</td>
        <td>{user.FullName ? <UserBidHistoryTable auctionDetail={auction} user={user} /> : 'N/A'}</td>
      </tr>
    </>
  )
}