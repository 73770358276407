import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { showLoading } from 'services/util'

import { urlQueryParser } from '../util/urlQueryParser'
import { getPublicAuctionDetail } from '../api'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'
import { getImageUrl } from '../util/images'

import ItemInfoBox from './ItemInfoBox'

import css from './sass/itempage.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function Item(props) {  
  const [itemData, setItemData] = useState()
  const [imageUrl, setImageUrl] = useState()

  const [auctionDetail, setAuctionDetail] = useState()

  const { auctionId: auctionCode, itemId: queryItemCode } = urlQueryParser(props.location.search)

  // load auction data by code, so it will work for deep-linking
  useEffect(() => {
    if (!auctionCode)
      return

    showLoading(true)

    const getDetails = async () => {
      const {success, error} = await getPublicAuctionDetail(auctionCode)
      showLoading(false)

      if (error || !success || !success.auction || success.auction.error) {
        console.log('Error!', error ||  success?.auction?.error || "No auction found")
      }
      else if (success.auction) {
        setAuctionDetail(success.auction)
      }
    }

    getDetails()

  }, [auctionCode])
  

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auctionDetail?.AuctionCode, itemData?.ItemCode, itemData?.ImageFilename)
    setImageUrl(url)
  }, [auctionDetail, itemData])

  useEffect(() => {
    if (!auctionDetail) return

    setItemData(auctionDetail.AuctionItems.find(item => item.ItemCode === queryItemCode))
  }, [auctionDetail, queryItemCode])


  if (!itemData) return (<></>)

  return (
    <>
      <div className={css['item-page-path']}>
        <Link className={css['path-text']} to={{ pathname: '/browse/auction', search: `?auctionId=${auctionDetail.AuctionCode}` }}> {auctionDetail.Title} </Link>
        <p className={css['path-dash']}> / </p>
        <p className={css['path-text']}>{itemData.Title} </p>
      </div>
      <div className={`${css['item-container']} ${themeCss['blue-background']}`}>
        <img className={css['item-page-img']} src={imageUrl} alt={itemData.Title} />
        <div className={css['item-bottom-info']}>
          <CustomMarkdown text={itemData.Description} cssClass={css['item-description']} />
          <ItemInfoBox item={itemData} />
        </div>
      </div>
    </>
  ) 
}