import { useEffect, useState } from 'react'

import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import { urlQueryParser } from '../util/urlQueryParser'
import { getPublicAuctionDetail } from '../api'
import { getAuctionStatus } from "../util/dateString"

import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'
import ItemCardBrowse from '../items/ItemCardBrowse'
import AuctionCountdown from './AuctionCountdown'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctions.module.scss'

export default function Auction(props) {
  //destructuring auction code from url
  //called id in url query for that convention, but using auction code in aws to declutter url from being massive from aws UUID for auction ID to link tables
  const { auctionId: auctionCode, ue: userExists } = urlQueryParser(props.location.search)

  const [auctionStatus, setAuctionStatus] = useState()
  const [auctionDetail, setAuctionDetail] = useState()

  useEffect(() => {
    if (!auctionCode)
      return

    showLoading(true)

    const getDetails = async () => {
      const {success, error} = await getPublicAuctionDetail(auctionCode)
      showLoading(false)

      if (error || !success || !success.auction || success.auction.error) {
        console.log('Error!', error ||  success?.auction?.error || "No auction found")
      }
      else if (success.auction) {
        setAuctionDetail(success.auction)
        setAuctionStatus(getAuctionStatus(success.auction.StartTime, success.auction.EndTime))
      }
    }

    getDetails()

  }, [auctionCode])

  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!auctionDetail || auctionStatus === 'ended') return (<></>)

  return (
    <div className={css['auction-container']}>
      <h2 id="landingPageTitle">
        {auctionDetail.Title}
      </h2>
      
      <div className={themeCss['blue-background']}>

        <div>
          <AuctionCountdown auctionDetail={auctionDetail} auctionState={auctionStatus} isBrowse={true} />
        </div>

        <div className={css['browse-auction-description']}>
          <CustomMarkdown text={auctionDetail.Description} cssClass={css['auction-description-browse']} />
        </div>


          <div className={css['auction-email-buttons']}>
            <button
            onClick ={(e) => {history.push({
              pathname: userExists ? '/signin' : '/registration',
            })}}
            className={`${themeCss['FilledYellow']} ${themeCss['button']}`}>Sign {auctionStatus === 'before' ? "Up" : "In"} for Auction</button>
          </div>

        <div className={css['auctionitems-container']}>

          {/* for non registered viewers of the auction: */}
          {auctionDetail.AuctionItems && auctionDetail.AuctionItems.map((item) => {
            return <ItemCardBrowse key={item.ItemCode} item={item} auction={auctionDetail} />
          })}
        </div>

        <p className={css['auction-description']}>
          <br />
          MAILING REMINDER:
          <br />
          Winning bidder incurs all costs for shipping and handling.
          <br />
          Large and heavy items cannot be mailed.
          <br />
          Happy browsing and bidding! Thanks!
        </p>

      </div>
    </div>
  )
}