import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/myitemcard.module.scss'

import { getImageUrl } from '../util/images'
import FavoriteItem from '../items/FavoriteItem'
import { getItemUserStatus } from '../components/utils'
import { formatMoney } from 'services/util'

export default function MyItemCard({item, auction, currentUserId}) {

  const [imageUrl, setImageUrl] = useState()

  const itemUserStatus = getItemUserStatus(auction, item, currentUserId)
  const outlineClass = itemUserStatus ? themeCss[`outline-${itemUserStatus}`] : ''

  const currentBidText = () => {
    if (auctionState === 'ended') return 'Winning Bid'
    if (item.CurrentBid) return 'Current Bid'
    return 'Starting Bid'
  }
  
  let auctionState = ''
  const now = new Date()
  const startDate = new Date(auction.StartTime)
  startDate < now ? auctionState = 'started' : auctionState = 'before'


  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction, item])

  const isHighBidder = item.CurrentHighBidder === currentUserId

  if (auctionState === 'ended' && !isHighBidder) return <></> 

  return (
    <div className={`${css['myitem-container']} ${themeCss['shadowed']} ${outlineClass}`}>
      <Link className={css['myitem-image']} to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>
        <img src={imageUrl} alt={`${item.Title}`} />
        <p className={`${css['myitem-title']} ${themeCss['truncate-twoline']}`}>
          {item.Title}
        </p>
      </Link>

      <div className={css['myitem-notification-row']}>
        <p className={itemUserStatus === 'winning' ? themeCss['high-bid-notification'] : themeCss['hidden']}>
          You are the highest bidder
        </p>
        <p className={itemUserStatus === 'outbid' ? themeCss['outbid-notification'] : themeCss['hidden']}>
          You have been outbid
        </p>
      </div>

      {auctionState !== 'before' && 
      <div className={css['myitem-bidinfo']}>
        <p>
          {currentBidText()} 
            <span>
              ${formatMoney(item.CurrentBid ? item.CurrentBid : item.StartingBid)}
            </span>
        </p>
        <p className={css['myitem-bidcount']}>{item.BidCount} {item.BidCount === 1 ? 'bid' : 'bids'}</p>
      </div>}

      <div className={css['myitem-footer']}>
        <FavoriteItem auctionId={auction.AuctionId} itemId={item.ItemId}/>
        <Link to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}
            className={`${css['myitem-view-link']}`}>
          View Item Details
        </Link>
      </div>

    </div>
  )
}