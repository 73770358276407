import { useEffect, useState } from 'react'

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { updateCurrentBidMutation } from '../graphql/Mutations'
import { ProxyWarning, ErrorIcon, LinkButton } from 'common/header/HeaderStyles'

import { graphql, registerUserForAuction, getAuctionByCode } from '../api'
import { sendAllPaymentReminderEmails, adminCleanupPreviewAuction } from './auctionsadminutil'

import warningIcon from 'images/icon-warning.svg';

export default function PreviewAuctionHeader(props) {

  const simulateAuctionStart = async () => {
    sessionStorage.setItem('previewStartTime', new Date().toISOString())
    window.location.reload()
  }

  const simulateAuctionEnd = async () => {
    showLoading(true)
    sessionStorage.setItem('previewEndTime', new Date().toISOString())
    const sendPaymentRemindersResponse = await sendAllPaymentReminderEmails(sessionStorage.previewAuctionId)
    console.log({sendPaymentRemindersResponse})
    window.location.reload()
    showLoading(false)
  }

  const simulateOutbidAlert= async () => {
    showLoading(true)

    try {
      const auction = await getAuctionByCode(sessionStorage.previewAuctionCode)

      if (auction.Bids.length === 0) {
        alert('You need to bid on an item first.')
        showLoading(false)
        return;
      }

      const lastBid = auction.Bids[auction.Bids.length-1]
      const item = auction.Items.find(item => item.ItemId === lastBid.ItemId)

      const registerResponse = await registerUserForAuction(sessionStorage.previewAuctionCode, "", true)

      const variables = {userId: "{PreviewUserId}", bidAmount: lastBid.BidAmount+item.MinRaise, itemId: lastBid.ItemId}
      const updateBidResponse = await graphql({query: updateCurrentBidMutation, variables })
    }
    catch (e) {
      alert('Something went wrong. Please try again or reach out to the auctions development team.')
      console.error(e)
    }

    showLoading(false)
  }

  const exitPreviewMode = async () => {
    showLoading(true)
    await adminCleanupPreviewAuction(sessionStorage.previewAuctionId)
    showLoading(false)
    const previewAuctionCode = sessionStorage.previewAuctionCode
    sessionStorage.clear()
    window.document.location.href = localStorage.isSolicitationReviewAdmin ? "/admin/listpreviewauctions" : "/admin/auctiondetail?auctionId="+previewAuctionCode
  }


  if (!sessionStorage.previewAuctionCode)
    return <></>

  return (
    <ProxyWarning>
      <ErrorIcon src={warningIcon}/>
      <span>
        You are currently in Preview Mode:&nbsp;

        <LinkButton onClick={exitPreviewMode}>Exit Preview Mode</LinkButton>

        {!sessionStorage.previewStartTime &&
          <>&nbsp;|&nbsp;<LinkButton onClick={simulateAuctionStart}>Simulate Auction Start</LinkButton></>}

        {sessionStorage.previewStartTime && !sessionStorage.previewEndTime &&
          <>&nbsp;|&nbsp;<LinkButton onClick={simulateAuctionEnd}>Simulate Auction End</LinkButton></>}

        {sessionStorage.previewStartTime && !sessionStorage.previewEndTime &&
          <>&nbsp;|&nbsp;<LinkButton onClick={simulateOutbidAlert}>Simulate Outbid Alert</LinkButton></>}

      </span>
    </ProxyWarning>
  )
}