import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../api'
import { updateAuctionMutation } from '../graphql/Mutations'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/adminpage.module.scss'

import ExtendAuctionModal from './ConfirmModal/ExtendAuctionModal'

export default function ExtendAuctionButton({ auction, fromStatus, toStatus, refreshAdminAuctionQuery}) {

  const [showModal, setShowModal] = useState(false)
  const [triggerLoading, setTriggerLoading] = useState(false)
  
  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      setTriggerLoading(true)
      return graphql({query: updateAuctionMutation, variables: {input: input} })
    },
  })

  useEffect(() => {

    // trigger loading was needed because in sister components (inside the tabs) the showLoading would get overriden by the update auction status button loading
    // set up an internal check to see if the component itself needed to show loading,
    // and only affect the showLoading util function if it was being caused by THIS component
    const performErrorCheck = () => {
      if (error) {
        console.log("Auction not created: ", error)
        console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
      }
      else if (updateAuctionResponse) {
        console.log(updateAuctionResponse)
        console.log('Auction updated: ', updateAuctionResponse.data.updateAuction.AuctionId)
        //invalidate query to refresh the page from AdminAuctionTabs.js
        refreshAdminAuctionQuery()
        setShowModal(false)
      }
    }

    if (triggerLoading) {
      showLoading(isPending)
  
      performErrorCheck()

      setTriggerLoading(false)
      showLoading(isPending)

    } else {
      performErrorCheck()
    }
  }, [updateAuctionResponse, error, isPending, refreshAdminAuctionQuery, triggerLoading])


  return (
    <div className={css['extend-button-container']}>
      {(fromStatus !== "CLOSED" && toStatus !== "PREVIEW") && <button className={`${themeCss['FilledBlue']} ${themeCss['button']}
        `} onClick={() => setShowModal(true)}>
        Extend Auction Duration
      </button>}

      {showModal && 
        <ExtendAuctionModal setShowConfirmModal={setShowModal} saveAuction={saveAuction} auction={auction}
          /> }
    </div>
  )
}