import { graphql } from '../api'
import { deleteUserMutation, sendPaymentRemindersMutation, cleanupPreviewAuctionMutation, sendSolicitationReviewRequestMutation, updateAuctionMutation } from '../graphql/Mutations'
import { adminGetAuctionByCodeQuery, adminGetAuctionImagePresignedUrlQuery, adminFindUserQuery, adminListAuctionsQuery } from '../graphql/Queries'

export const adminFindUser = async (cognitoLogin) => {
  console.log('adminFindUser!', cognitoLogin)

  const response = await graphql({query: adminFindUserQuery, variables: {cognitoLogin}})
  if (!response?.data?.adminFindUser) {
    console.log(response)
    return({"error": response})
  }
  else {
    const user = JSON.parse(JSON.stringify(response.data.adminFindUser))

    return user
  }
}

// selects all if status is missing or 'ALL'
export const adminListAuctions = async (statusInput) => {
  console.log('adminListAuctions!', {statusInput})

  const status = statusInput || "ALL"

  const query = localStorage.isSeleniumTest ? adminListAuctionsQuery.replace('Status', 'IsForTestOnly: {eq: true}, EndTime') : adminListAuctionsQuery
  const response = await graphql({query, variables: {status}})

  if (!response?.data?.adminListAuctions?.items) {
    console.error("error: ", response)
  }
  else {
    const auctionList = response.data.adminListAuctions.items
    return auctionList
  }
}


// IMPORTANT: deletes Auction User only, does not delete CIDM User
export const deleteAuctionUser = async (cognitoLogin) => {
  console.log('deleteAuctionUser!')
    try {

      const response = await graphql({query: deleteUserMutation, variables: {cognitoLoginToDelete: cognitoLogin}})

      if (response.errors) {
        console.error("User not deleted: ", response.errors[0].message)
        return {error: {msg: response.errors[0].message}}
      }
      else if (!response?.data?.deleteUser?.UserId) {
        console.error("User not deleted: ", response)
        return response
      }
      else {
        const auctionUser = {UserId: response.data.deleteUser.UserId, MyAuctions: []}
        return auctionUser
      }
    }
    catch (e) {
      console.error("error in delete:", e)
      return {error: e}
    }
}


export const adminGetAuctionByCode = async (auctionCode) => {
  console.log('adminGetAuctionByCode!', auctionCode)
  const response = await graphql({query: adminGetAuctionByCodeQuery, variables: {auctionCode}})
  if (!response?.data?.adminGetAuctionByCode) {
    console.log(response)
    return({"error": response})
  }
  else {
    const auction = JSON.parse(JSON.stringify(response.data.adminGetAuctionByCode))

    // TODO: commenting this out event hub syncing because we don't need it for the first auction
    // if (auction.Registrations.length !== auction.EventHubRegistrations.length)
    //   return({"error": {msg: "Mismatch between Event Hub registrations and Auction regsitrations", response}})

    // we have to do this here because AppSync doesn't allow access to nested data resolvers in the parent pipeline
    // auction.Registrations = auction.Registrations.sort((a,b) => a.EventHubRegistrationId.toLowerCase().localeCompare(b.EventHubRegistrationId))
    // auction.EventHubRegistrations = auction.EventHubRegistrations.sort((a,b) => a.RegistrationId.toLowerCase().localeCompare(b.RegistrationId))
    // auction.Registrations.forEach((reg, index) =>{
    //   reg.IsCheckedIn = auction.EventHubRegistrations[index].RegistrationStatus === "CHECKED_IN"
    // })

    // TODO - everyone who registered for the first auction is checked in already
    auction.Registrations.forEach((reg, index) =>{
      reg.IsCheckedIn = true
    })

    delete auction.EventHubRegistrations

    return auction
  }
}

export const adminCleanupPreviewAuction = async (auctionId) => {
  console.log('adminCleanupPreviewAuction!', auctionId)
  const response = await graphql({query: cleanupPreviewAuctionMutation, variables: {auctionId}})
  if (!response?.data?.cleanupPreviewAuction) {
    return({"error": response})
  }
  else {
    return response
  }
}

export const sendAllPaymentReminderEmails = async (auctionId) => {
  console.log('sendAllPaymentReminderEmails!')

  const response = await graphql({query: sendPaymentRemindersMutation, variables: {auctionId}})
  if (!response?.data?.sendPaymentReminders || !response.data.sendPaymentReminders.AuctionId) {
    console.log(response)
    return({"error": response})
  }
  else {
    return response
  }
}

export const sendSolicitationReviewRequest = async (auctionId) => {
  console.log('sendSolicitationReviewRequest!')

  const response = await graphql({query: sendSolicitationReviewRequestMutation, variables: {auctionId}})
  if (!response?.data?.sendSolicitationReviewRequest || !response.data.sendSolicitationReviewRequest.AuctionId) {
    console.log(response)
    return({"error": response})
  }
  else {
    return response
  }
}


export const adminGetAuctionImagePresignedUrl = async (auctionCode, itemId, fileName, contentType) => {
  console.log('adminGetAuctionImagePresignedUrl!')

  const response = await graphql({query: adminGetAuctionImagePresignedUrlQuery, variables: {auctionCode, itemId, fileName, contentType}})
  if (!response?.data?.getPresignedUrlForUpload || !response.data.getPresignedUrlForUpload.PresignedUrl) {
    console.log(response)
    return({"error": response})
  }
  else {
    const presignedUrl = response.data.getPresignedUrlForUpload.PresignedUrl

    return presignedUrl
  }
}

export const formatName = (registration) => {
  console.log(registration)
  return `${registration.User.FirstName} ${registration.User.LastName}`
}

//admin sort table functions
// see ClosedItemDisplay.js for an example 

  // Handler for column header clicks
  export const handleSort = (key, sortOptions, setSortOptions) => {
    let direction = 'asc'
    if (sortOptions.key === key && sortOptions.direction === 'asc') direction = 'desc'
    setSortOptions({ key, direction })
  };

  export const sortSpan = (columnToSort, sortOptions) => {
    if (sortOptions.key === columnToSort && sortOptions.direction === 'asc') return <span>▲</span>
    if (sortOptions.key === columnToSort && sortOptions.direction === 'desc') return <span>▼</span>
  }

  export const performSort = (inputArray, sortOptions) => {
    return [...inputArray].sort((a, b) => {
      if (sortOptions.key === '') return 0 // No sorting if no key is set
  
      const aValue = a[sortOptions.key]
      const bValue = b[sortOptions.key]

      if (aValue < bValue) return sortOptions.direction === 'asc' ? -1 : 1
      if (aValue > bValue) return sortOptions.direction === 'asc' ? 1 : -1
      return 0;
    })
  }

  //to check if the auction has at least one item for check lists
  export const auctionHasItems = (auctionDetail) => {
    if (!auctionDetail) return
    const anyItems = auctionDetail?.Items?.length > 0

    return anyItems
  }

  // to check if the auction has any empty fields for check lists
  export const auctionDetailsComplete = (auctionDetail) => {
    if (!auctionDetail) return false

    //fields required for the check - all are strings in the schema
    const neededDetails = ['AuctionId', 'AuctionCode', 'AuctionLocation', 'BannerImageName', 'Description', 'EndTime',
      'SupportEmail', 'FundName', 'FundNumber', 'MarketingEffort', 'StartTime', 'Title', 'Status']

    // check each field to see if it is
    //  A) a string (user entered value rather than null from Auction creation)
    // AND 
    //  B) has a length > 0 (user has a valid value instead of entering then deleting)

    for (let i=0; i < neededDetails.length; i++) {
      const key = neededDetails[i]
      if (typeof(auctionDetail[key]) !== 'string') return false
      if (auctionDetail[key].length === 0) return false
    }
    //if all required fields are strings and have a length, auction details considered complete
    return true
  }

