import { useState, useEffect } from "react"

import { useSelector } from "react-redux"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import { getAuctionUser } from 'sites/auctions/redux/selectors'
import { registerUserForAuction } from '../api'
import { cardValidCheck } from "../register/flow/verifyUtils"

import infoIcon from '../components/images/info-icon-orange.svg'

import css from './sass/payment.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function PaymentValid() {

  const [cardStatus, setCardStatus] = useState('valid')
  const [lastFour, setLastFour] = useState()
  const [exp, setExp] = useState()
  const [paymentMethods, setPaymentMethods] = useState([])
  
  const auctionUser = useSelector(getAuctionUser)
  console.log(auctionUser);

  useEffect(() => {
    if (auctionUser) {
      const payments = auctionUser.PaymentMethods
      setPaymentMethods(payments)
      
      //assuming only one card
      setLastFour(payments[0].Last4)
      setExp(payments[0].Expires)
    }

  }, [auctionUser])

  useEffect(() => {
    if (exp) {
      if (cardValidCheck(exp)) setCardStatus('valid')
      else setCardStatus('expire')
    }
  }, [exp])

  const paymentApproved = async (e) => {
    e.preventDefault()
    console.log("paymentApproved called")

    showLoading(true)

    try {
      const savedAuctionCode = localStorage.getItem('savedAuctionCode')
      if (savedAuctionCode) {
        const registerResponse = await registerUserForAuction(savedAuctionCode)
        console.log({registerResponse})
      }

      showLoading(false)
      //reroute to add payment page on successful update
      history.push('/flow/paymentsuccess')
    }
    catch(e) {
      // TODO: probably should do something here since it could mean that the user isn't registered for the auction, not sure what
      console.log('error registering user!', e)
      showLoading(false)
    }
  }

  return (
    <div className={css['payment-container']}>
      <h2 className={css['payment-header']}>Payment Information</h2>
      
      <p>Please verify the payment info we have on file for you.</p>

      {cardStatus === 'expire' && <div className={css['payment-expire-note']}>
          <img src={infoIcon} alt="Expired or Expiring Soon Notice" />
          <p>It looks like your payment card is expired or expiring soon. Please update your payment information.</p>
        </div>}

      <div className={css[`payment-card-display-${cardStatus}`]}>
        <p>Card Number</p>
        <p><strong>{`XXXX-XXXX-XXXX-${lastFour}`}</strong></p>

        <p>Exp. Date</p>
        <p><strong>{exp}</strong></p>

      </div>

      <div className={css['payment-button-container']}>
        {cardStatus === 'valid' && <button 
          onClick ={paymentApproved}
          className={`${themeCss['FilledBlue']} ${themeCss['button']}`}>
            Use This Payment Info</button>
            }

        <button 
          onClick ={(e) => {history.push({pathname: '/flow/paymentupdate' }) }}
          className={`${themeCss['button']} ${themeCss['FilledWhite']} ${css['payment-button-text']}`}>
            Update Payment Info</button>
      </div>
    </div>
  )
}