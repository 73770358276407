import { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { getLoggedInUser } from 'redux/selectors';
import store from 'redux/store';
import { showLoading } from 'services/util';
import { device } from 'common/Device'
import { lightGray } from 'css/Colors'
import { FilledYellow, StrokeYellow, StrokeBlue } from 'css/Buttons';
import MyInfoIcon from 'images/MyInformation.svg'

import HomePageBenefitsSummary from '../components/MoreSummary'
import PromoBanner from  '../components/PromoBanner';

import SparkFeed from './SparkFeed'
import BannerWarning from './BannerWarning'
import LandingPromoBanner from '../images/LandingPromoBanner.jpg'
import GivingHistoryIcon from '../images/GivingHistory.svg'
import PrintReceiptsicon from '../images/PrintReceipts.svg'

const defaultFontSize = 16

const BannerAnchorDiv=styled.div`
  padding-bottom:25px;
  & >a{
    width: 260px;
  }
`

const BenefitsContainer=styled.div`
  padding:${ 65/defaultFontSize }rem 0 ${ 50/defaultFontSize}rem;
  h3 { margin-top:0; }
  @media ${ device.tablet } {
    padding:${ 35/defaultFontSize }rem 0 ${ 30/defaultFontSize }rem;

    &&&{
    margin:0;
    }
    & >div[class*='span-']{
      padding:0;
    margin:0;
    margin-top:calc(40rem/16);
    }
  }
  @media ${ device.mobile } {
    padding-top:${ 20/defaultFontSize }rem;
  }
`

const BenefitsCopy = styled.p`
  margin-bottom:${ 40/defaultFontSize }rem;
  font-weight: bold;
  @media ${ device.tablet } {
    margin-bottom:${ 30/defaultFontSize }rem;
  }
  @media ${ device.mobile } {
    margin-bottom:${ 14/defaultFontSize }rem;
  }
`

const OutboundLink = styled.div`
  display:flex;
  justify-content:center;
  padding-top:${ 30/16 }rem;
  padding-bottom:${ 50/16 }rem;
`

const BackgroundWrapper = styled.div`
  background:#ffffff;
`

const LinkWrapper = styled.div`
  background:${ lightGray };
`

class HomeComponent extends Component {

  componentDidMount() {
    showLoading(false);
  }

  render(){
    const loggedInUser = getLoggedInUser(store.getState())
    return (
      <>
        <BannerWarning />
        <BackgroundWrapper>
          <PromoBanner  icon={LandingPromoBanner} page={"landing"}>
            <h1 id="landingPageTitle" className="banner-header">My Giving Gateway</h1>
            {!loggedInUser &&
            <>
              <BannerAnchorDiv>
                <FilledYellow
                  to={"/signin"}
                  as={NavLink}
                  >
                  <span> Sign In</span>
                </FilledYellow>
              </BannerAnchorDiv>

              <BannerAnchorDiv>
                <StrokeYellow
                  to={"/registration"}
                  as={NavLink}
                >
                  <span>Create Account</span>
                </StrokeYellow>
              </BannerAnchorDiv>
            </>}
          </PromoBanner>

          {/* Summary(Benefits) of using the Donor Portal   */}
          <LinkWrapper>
            <div className="wrapper">
              <BenefitsContainer>
                <div className="grid">
                  <BenefitsCopy className="span-12 center">As a UCLA donor, you are invaluable to the work of the university. We created My Giving Gateway to provide ease and transparency when you want to see your past contributions to UCLA, print receipts, or update your contact information. Let’s keep in touch.</BenefitsCopy>
                </div>

                <div className="grid">
                  <div className="span-4">
                    <HomePageBenefitsSummary as={NavLink} icon={GivingHistoryIcon} to={"/history"} >
                      <h3 className="has-dots h4">Giving History</h3>
          					</HomePageBenefitsSummary>
                  </div>

                  <div className="span-4">
                    <HomePageBenefitsSummary as={NavLink} icon={PrintReceiptsicon} to={"/history"} >
                      <h3 className="has-dots h4">Print Receipts</h3>
          					</HomePageBenefitsSummary>
                  </div>

                  <div className="span-4">
                    <HomePageBenefitsSummary as={NavLink} icon={MyInfoIcon} to={"/profile"} >
                      <h3 className="has-dots h4">My Information </h3>
          					</HomePageBenefitsSummary>
                  </div>
                </div>

              </BenefitsContainer>
            </div>
          </LinkWrapper>

          <SparkFeed />

          <OutboundLink className="wrapper">
            <StrokeBlue as={"a"} href={"https://crowdfunding.ucla.edu"} rel={"noopener nofollow noreferrer"} target={"_blank"}>See All Personal Projects</StrokeBlue>
          </OutboundLink>
        </BackgroundWrapper>
      </>
    )
  }
}

export default withRouter(HomeComponent);
