import themeCss from '../../components/sass/theme.module.scss'

export default function ClosedAuctionAttendeeItemRow( {item, selectedItems, toggleCheckbox} ) {

  return (
    <tr>
      <td>
        <input type='checkbox'
          checked={selectedItems.indexOf(item.ItemId) > -1}
          onChange={() => toggleCheckbox(item.ItemId)} 
          />
      </td>
      <td>{item.Title}</td>
      {item.Paid ? <td>$0</td> : <td className={item.CurrentBid > item.Value ? themeCss['boldGreen']: ''}>${item.CurrentBid}</td>}
      {item.Paid ? <td className={item.CurrentBid > item.Value ? themeCss['boldGreen'] : ''}>YES</td> : <td>NO</td>}
    </tr>
  )
}