import { useState, useEffect, useCallback } from 'react'

import { showLoading } from 'services/util'
import { handleEscapeKey } from 'sites/auctions/components/utils'

import NumberRow from './NumberRow'
import CheckoutAddress from 'sites/auctions/checkout/CheckoutAddress'

import css from '../../components/sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function ConfirmMakePayment({setShowConfirmModal, data, adminCharge}) {

  const [address, setAddress] = useState()
  const [isValidAddress, setIsValidAddress] = useState(false)

  const toggleModal = useCallback(() => {
    setShowConfirmModal(false)
  }, [setShowConfirmModal])

  useEffect(() => {
    const onKeyDown = (event) => handleEscapeKey(event, toggleModal)
    //add event listener for when modal is mounted
    document.addEventListener('keydown', onKeyDown)
    //clean up when modal is unmounted
    return () => document.removeEventListener('keydown', onKeyDown)

  }, [toggleModal])

  useEffect(() => {

    // if user has CRM, address is not needed
    if (data.endUserCrmId) {
      setIsValidAddress(true)
      return
    }

    // for international addresses, all state and postal code is in the city field
    if (address?.country !== 'United States') {
      setIsValidAddress(address?.streetAddress1 && address?.intlCity)
    }
    else {
      setIsValidAddress(address?.streetAddress1 && address?.city && address?.state && address?.zipCode)
    }
  }, [address, data.endUserCrmId])



  const performConfirm = () => {

    const input = {
      auctionId: data.auctionId,
      endUserId: data.endUserId,
      totalAmount: data.totalCharged,
      itemIds: data.itemsCharged,
    }

    if (!data.endUserCrmId) {
      input.address = {
        Street1: address.streetAddress1,
        Street2: address.streetAddress2,
        City: address.city || address.intlCity,
        State: address.state,
        PostCode: address.zipCode,
        Country: address.country,
      }
    }

    showLoading(true)
    adminCharge(input)
  }


  return (
    <div className={css['modal-container']}>
      <div className={themeCss['modal-background']} onClick={()=>toggleModal()}> </div>
      <div className={`${css['modal-content']} `}>

        <div className={css['withdata-body']}>
          <div className={css['withdata-text']}>
            <h3>CONFIRM CHARGES</h3>
              <NumberRow number={data.itemsCharged.length} dollar={false} text={'ITEM(S) BEING CHARGED'} />
              <NumberRow number={data.totalCharged} dollar={true} text={'TOTAL CHARGES'} />
              {data.endUserCrmId === "" && <CheckoutAddress updateAddress={setAddress} address={undefined} />}
          </div> 
        </div>

        {/* {data.errorMessage && <p>Error</p>} */}
        

        <div className={css['button-options']}>
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${themeCss['centered-button']}`}
              onClick={()=>toggleModal()} >Cancel</button>
          
          <button disabled={!isValidAddress}
            className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> performConfirm()}>Submit Charges for Processing</button>
        </div>
      </div>    
    </div>
  )
}