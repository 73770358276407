import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"

import { subscribe } from '../api'
import { onUpdateCurrentBidSubscription } from '../graphql/Subscriptions'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { getAuctionUser } from '../redux/selectors'
import { getAuctionByCode } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'
import { getAuctionStatus } from '../util/dateString'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'

import css from './sass/itempage.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import ItemBid from './ItemBid'
import ItemInfoBox from './ItemInfoBox'
import { getImageUrl } from '../util/images'

export default function Item(props) {  
  const [itemData, setItemData] = useState()
  const [needsRefresh, setNeedsRefresh] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [auctionState, setAuctionState] = useState()
  const [isCheckedIn, setIsCheckedIn] = useState(false)
  const auctionUser = useSelector(getAuctionUser);
  
  const [initialBid, setInitialBid] = useState(null)

  const { auctionId: queryAuctionCode, itemId: queryItemCode } = urlQueryParser(props.location.search)
  const queryClient = useQueryClient()
  
  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, queryError, isLoading } = useQuery({
    queryKey: ["auctionDetail", queryAuctionCode],
    queryFn: async () => await getAuctionByCode(queryAuctionCode),
    staleTime: 0
  })
  
  const auctionId = auctionDetail?.AuctionId
  
  //refresh the auctionDetail data
  if (needsRefresh) {
    let updatedItem = {...itemData, ...needsRefresh}
    queryClient.invalidateQueries(["auctionDetail"])
    setItemData(updatedItem)
    setNeedsRefresh(undefined)
  }
  
  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auctionDetail?.AuctionCode, itemData?.ItemCode, itemData?.ImageFilename)
    setImageUrl(url)
  }, [auctionDetail, itemData])

  useEffect(() => {
    showLoading(isLoading)

    if (queryError || (auctionDetail && auctionDetail.queryError)) {
      console.log('Error!', queryError || auctionDetail.queryError)
    }
    else if (auctionDetail) {
      setItemData(auctionDetail.Items.find(item => item.ItemCode === queryItemCode))
      setAuctionState(getAuctionStatus(auctionDetail.StartTime, auctionDetail.EndTime))
    }
  }, [auctionDetail, isLoading, queryError, queryItemCode])

  // this is executed any time a bid is successfully updated
  // use the result to update the current bid for that item on the client
  useEffect(() => {
    if (!auctionId)
      return

    const subCreateFiltered = subscribe({query: onUpdateCurrentBidSubscription, variables: {auctionId}}, {
      next: (result) => {
        console.log('subscription result', result)
        setNeedsRefresh(result.value.data.onUpdateCurrentBid)
      },
      error: (error) => console.error("Subscription error!", error)
    })

    return () => {
      console.log('unsubscribing...')
      subCreateFiltered.unsubscribe()
    }
  }, [auctionId])

  useEffect(() => {
    console.log({auctionState})
  }, [auctionState])

  //checking registration for checked in status and text alert status
  useEffect(() => {
    if (!auctionDetail || !auctionUser) return

    const myRegistration = auctionUser.MyAuctions.filter(registration => {
      return registration.Auction.AuctionId === auctionDetail.AuctionId
    })[0]

    if (myRegistration) {
      setIsCheckedIn(myRegistration.IsCheckedIn)
    }

  }, [auctionDetail, auctionUser])
  
  console.log('needsRefresh', needsRefresh)
  if (!itemData) return (<></>)

  return (
    <>
      <div className={css['item-page-path']}>
        <Link className={css['path-text']} to={{ pathname: '/auctionhome', search: `?auctionId=${auctionDetail.AuctionCode}` }}> {auctionDetail.Title} </Link>
        <p className={css['path-dash']}> / </p>
        <p className={css['path-text']}>
          {itemData.Title} 
        </p>
      </div>
      <div className={`${css['item-container']} ${themeCss['blue-background']}`}>
        <img className={css['item-page-img']} src={imageUrl} alt={itemData.Title} />
        {auctionState !== 'before' && <ItemBid item={itemData} auctionDetail={auctionDetail} auctionState={auctionState}
          isCheckedIn={isCheckedIn} outBidNotification={props.location.outbidNotification}
          initialBid={initialBid} setInitialBid={setInitialBid}
          />}
        
        <div className={css['item-bottom-info']}>
          <CustomMarkdown text={itemData.Description} cssClass={css['item-description']} />
          <ItemInfoBox item={itemData} />
        </div>
      </div>
    </>
  ) 
}