import MessageIcon from './images/message-icon.svg'

import { showLoading } from 'services/util'

import { userDeclinesTextAlerts } from '../register/flow/verifyUtils'
import { isAuctionOver } from '../util/dateString'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctionscards.module.scss'

export default function TextMessageOn({auction, auctionUser}) {

  const declineRequest = async () => {

    showLoading(true)

    try {
        const signUpResponse = await userDeclinesTextAlerts(auction, auctionUser)
        console.log({signUpResponse})
        if (signUpResponse.success) {
          console.log('user declined text messages')
        }
        else {
          // do error behavior
          console.log({signUpResponse})
        }
    

      showLoading(false)
    }
    catch (e) {
      showLoading(false)
      console.log(e)
    }
  }

  //if the auction has ended, do not show text message widgets
  if (isAuctionOver(auction)) return <></>

  return (
    <div className={themeCss['box-top-yellow-accent-wide']}>
      <div className={css['auction-box-info-row']}
      onClick={()=>declineRequest()}>
        <img className ={css['auction-box-info-icons']} src={MessageIcon} alt='Text Message Management Icon' />
        <p className={css['auction-box-info-link']}>Turn off text-message alerts</p>
      </div>
    </div>
  )
}
