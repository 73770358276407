import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import { adminCleanupPreviewAuction, auctionDetailsComplete, auctionHasItems, sendSolicitationReviewRequest } from './auctionsadminutil'
import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import PreviewPublishChecklist from './PreviewPublishChecklist'
import UpdateAuctionStatusButton from './UpdateAuctionStatusButton'
import AuctionStatusAlert from './AuctionStatusAlert'
import ExtendAuctionButton from './ExtendAuctionButton'
import { useSelector } from 'react-redux'
import { getAuctionUser } from '../redux/selectors'

export default function PreviewPublish(props) {

  const reviewStatus = props.auctionDetail?.ReviewStatus
  const passedReview = reviewStatus === 'Approved'
  const submittedForReview = reviewStatus === 'Submitted'
  
  // const auctionUser = useSelector(getAuctionUser)

  const [readyForSR, setReadyForSR] = useState(false)
  
  useEffect(() => {
  
      if (!props.auctionDetail) return
      const detailsComplete = auctionDetailsComplete(props.auctionDetail)
      const anyItems = auctionHasItems(props.auctionDetail)

      if (!detailsComplete || !anyItems) setReadyForSR(false)
      else setReadyForSR(true)

  }, [props.auctionDetail])

  // TODO: move to confirm modal, maybe make generic confirm modal
  const sendToSolicitation = async () => {
    console.log('send email request for solicitation review')
    showLoading(true)
    const response = await sendSolicitationReviewRequest(props.auctionDetail.AuctionId)
    props.refreshAdminAuctionQuery()
    console.log({response})
    showLoading(false)
  }

  const auctionStatus = props.auctionDetail?.Status

  const doPreview = async () => {
    await adminCleanupPreviewAuction(props.auctionDetail.AuctionId)
    history.push({ pathname: '/auctionhome', search: `?isPreview=true&auctionId=${props.auctionDetail.AuctionCode}`})
  }
  
  return (
    <div className={adminCss['widget-container']}>
      <div className={adminCss['widget-status-container']}>
        <AuctionStatusAlert liveStatus={props.liveStatus} auctionStatus={auctionStatus} />
        <PreviewPublishChecklist liveStatus={props.liveStatus} auctionDetail={props.auctionDetail} location={'widget'} />
      </div>

      {auctionStatus === 'PREVIEW' && <div className={adminCss['widget-button-container']}>

        <button className={`${themeCss['button']} ${themeCss['FilledWhite']}`} onClick={doPreview}>Preview Test Site</button>

        {!passedReview && !submittedForReview &&
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']}`}
            disabled={!readyForSR}
            title={readyForSR ? '' : 'All Auction Details and Items are all required before sending to Solicitation Review'}
            onClick={() => {sendToSolicitation()}}>
            Send Approval Request to Solicitation Review
          </button>}

        <div className={adminCss['solicitation-input-container']}>
          <p>Solicitation Review Status:
            <span className={adminCss[`${reviewStatus}`]}>{reviewStatus ? reviewStatus : 'Not submitted'}</span>
          </p>
        </div>

        
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PREVIEW'} toStatus={'PUBLIC'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} 
          needsSolicitation={!passedReview} />
      </div>}
      
      {auctionStatus === 'PUBLIC' && <div className={adminCss['widget-button-container']}>
        {/* from public to preview is prevented in UpdateAuctionStatus
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'PREVIEW'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} /> */}
        {props.liveStatus === 'started' &&
        <div className={adminCss['started-button-container']}>
          <ExtendAuctionButton auction={props.auctionDetail} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
          <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'ENDED'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
        </div>}
        {props.liveStatus === 'ended' &&
          <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'CLOSED'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />}
      </div>}
      {auctionStatus === 'CLOSED' && <div>
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'CLOSED'} toStatus={'PUBLIC'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
      </div>}
      
      
    </div>
  )
}