import { useState, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../api'
import { updateAuctionMutation } from '../graphql/Mutations'

import css from './sass/createedit.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import UploadFiles from './UploadFiles'
import { getAuctionStatus, forceTimezone, displayUtcInPacific } from '../util/dateString'
import { getImageUrl } from '../util/images'

export default function AuctionEdit({ auction, refreshAdminAuctionQuery}) {
  // console.log('auction', auction)
  const [auctionCode, setAuctionCode] = useState(auction?.AuctionCode)
  const [auctionLocation, setAuctionLocation] = useState(auction?.AuctionLocation)
  const [bannerImage, setBannerImage] = useState(auction?.BannerImageName)
  const [description, setDescription] = useState(auction?.Description)
  const [endTime, setEndTime] = useState(auction?.EndTime?.substring(0,16))
  const [needsCheckin, setNeedsCheckin] = useState(auction?.NeedsCheckin || false)
  const [startTime, setStartTime] = useState(auction?.StartTime?.substring(0,16))
  const [title, setTitle] = useState(auction?.Title)
  // const [organizerAddress, setOrganizerAddress] = useState(auction?.OrganizerAddress)
  // const [organizerLogo, setOrganizerLogo] = useState(auction?.OrganizerLogoName)
  // const [organizerName, setOrganizerName] = useState(auction?.OrganizerName)
  // const [organizerWebsite, setOrganizerWebsite] = useState(auction?.OrganizerWebsite)
  const [supportEmail, setSupportEmail] = useState(auction?.SupportEmail)
  // const [supportPhone, setSupportPhone] = useState(auction?.SupportPhone)
  const [fundName, setFundName] = useState(auction?.FundName)
  const [fundNumber, setFundNumber] = useState(auction?.FundNumber)
  const [marketingEffort, setMarketingEffort] = useState(auction?.MarketingEffort)

  //created variables to store the pacific time for display purposes
  // editing needed a stored value for display and one to submit to the database
  const [pacificStartTime, setPacificStartTime] = useState()
  const [pacificEndTime, setPacificEndTime] = useState()

  const [needsRequired, setNeedsRequired] = useState(false)
  const [invalidStart, setInvalidStart] = useState('')
  const [invalidEnd, setInvalidEnd] = useState('')
  const [disableEdit, setDisableEdit] = useState(true)
  const [bannerUrl, setBannerUrl] = useState('')

  // if the start of end time is not defined in the edit panel, the auction values should be null to match a missing value
  // once the time is set, they won't be able to remove it from the admin panel
  useEffect(() => {
    if (auction?.StartTime) setPacificStartTime(displayUtcInPacific(auction.StartTime))
    else {
      setPacificStartTime(null)
      setStartTime(null)
    }

    if (auction?.EndTime) setPacificEndTime(displayUtcInPacific(auction.EndTime))
    else {
      setPacificEndTime(null)
      setEndTime(null)
    }
  }, [auction.EndTime, auction.StartTime])

  const liveStatus = getAuctionStatus(auction?.StartTime, auction?.EndTime)
  
  let isReadOnly = (auction.Status === 'CLOSED' || liveStatus !== 'before') && auction.Status !== 'PREVIEW'
  
  
  const italicLabel = '<i>text to italicize</i>'

  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionMutation, variables: {input: input} })
    },
  })

  useEffect(() => {

    if (pacificEndTime && pacificStartTime) {
      setStartTime(forceTimezone(pacificStartTime, "America/Los_Angeles"))
      setEndTime(forceTimezone(pacificEndTime, "America/Los_Angeles"))
    }

  }, [pacificEndTime, pacificStartTime])

  //for when auction admin extends the auction duration while live, need to update the end time in edit auction
  useEffect(() => {
    if (auction.Status === 'PUBLIC') setPacificEndTime(displayUtcInPacific(auction.EndTime, "America/Los_Angeles"))
  }, [auction.EndTime, auction.Status])

  useEffect(() => {
    //check to see if anything has changed
    if (auctionCode !== auction.AuctionCode ||
    auctionLocation !== auction.AuctionLocation ||
    description !== auction.Description ||
    endTime !== auction.EndTime ||
    needsCheckin !== auction.NeedsCheckin ||
    startTime !== auction.StartTime ||
    title !== auction.Title ||
    // organizerAddress !== auction.OrganizerAddress ||
    // organizerLogo !== auction.OrganizerLogoName ||
    // organizerName !== auction.OrganizerName ||
    // organizerWebsite !== auction.OrganizerWebsite ||
    fundName !== auction.FundName ||
    fundNumber !== auction.FundNumber ||
    marketingEffort !== auction.MarketingEffort ||
    // supportPhone !== auction.SupportPhone ||
    supportEmail !== auction.SupportEmail) {
      setDisableEdit(false)
    } else setDisableEdit(true)

    //check to see if there is an invalid start or end time
    if (invalidStart !== '' || invalidEnd !== '') setDisableEdit(true)

  }, [auction, auctionCode, auctionLocation, bannerImage, description, endTime, needsCheckin, startTime, title,
      supportEmail, fundName, fundNumber, marketingEffort, invalidEnd, invalidStart])

  useEffect(() => {
    if (auctionCode === "" || title === "" ) setNeedsRequired(true)
    else setNeedsRequired(false)
  }, [auctionCode, title])

  //needed to refresh the auction details from inside upload files component properly
  useEffect(() => {
    setBannerImage(auction.BannerImageName) // fine maybe?
  }, [auction])

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Auction not created: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateAuctionResponse) {
      console.log(updateAuctionResponse)
      console.log('Auction updated: ', updateAuctionResponse.data.updateAuction.AuctionId)
      refreshAdminAuctionQuery()
        //trim after submission to update input fields
        setTitle(title?.trim())
        setStartTime(startTime?.trim())
        // setOrganizerWebsite(organizerWebsite?.trim())
        setSupportEmail(supportEmail?.trim())
        // setSupportPhone(supportPhone?.trim())
        setFundName(fundName?.trim())
        setFundNumber(fundNumber?.trim())
        setMarketingEffort(marketingEffort?.trim())
        // setOrganizerName(organizerName?.trim())
        // setOrganizerLogo(organizerLogo?.trim())
        // setOrganizerAddress(organizerAddress?.trim())
        setNeedsCheckin(needsCheckin)
        setEndTime(endTime?.trim())
        setBannerImage(bannerImage?.trim())
        setDescription(description?.trim())
        setAuctionLocation(auctionLocation?.trim())
    }
  }, [updateAuctionResponse, error, isPending, refreshAdminAuctionQuery, title, startTime, supportEmail, fundName, fundNumber, marketingEffort, 
    endTime, bannerImage, description, auctionLocation, needsCheckin])


  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, 'banner', bannerImage)
    setBannerUrl(url)
  }, [auction, bannerImage])

  const handleNeedCheckin = (e) => {
    const newValue = e.target.value
    if (newValue === 'true') setNeedsCheckin(true)
    if (newValue === 'false') setNeedsCheckin(false)
  }

  useEffect(() => {
    //only need to check if both startTime and endTime are not null
    if (!startTime || !endTime) return

    const now = new Date()
    const startDate = new Date(startTime)
    const endDate = new Date(endTime)

    setInvalidStart('')
    setInvalidEnd('')
    
    //can set up to only allow events to be set up a week, a day in advance, etc.
    if (now > startDate) setInvalidStart('Start date needs to be after current date and time.')
    if (endDate < startDate) setInvalidEnd('End date needs to be after the start date time.')
    if (now > endDate) setInvalidEnd('End date needs to be after current date and time.')

    //if the auction has already started or ended, then no error message is needed
    if (liveStatus === 'ended' || liveStatus === 'started') {
      setInvalidStart('')
      setInvalidEnd('')
    }

    //takes into account if either start or end time is not complete
    if (startTime?.length === 0) setInvalidStart('Please enter a start time.')
    if (endTime?.length === 0) setInvalidEnd('Please enter an end time.')

  }, [startTime, endTime, liveStatus])

  const onSubmit = async () => {

    const input = {
        Title: title.trim(),
        StartTime: startTime,
        // OrganizerWebsite: organizerWebsite?.trim(),
        SupportEmail: supportEmail?.trim(),
        // SupportPhone: supportPhone?.trim(),
        FundName: fundName?.trim(),
        FundNumber: fundNumber?.trim(),
        MarketingEffort: marketingEffort?.trim(),
        // OrganizerName: organizerName?.trim(),
        // OrganizerAddress: organizerAddress?.trim(),
        NeedsCheckin: needsCheckin,
        EndTime: endTime,
        BannerImageName: bannerImage?.trim(),
        Description: description?.trim(),
        AuctionLocation: auctionLocation?.trim(),
        AuctionId: auction.AuctionId,
        Status: auction.Status
      }
    saveAuction(input)
  }

  return (
    <div className={css['edit-container']}>
      <p className={css['label-notice']}>All fields required to submit for Soliciation Review</p>
      <div className={css['item-container']}>
        <div className={css['edit-item-input']}>
          <label>Title</label>
          <input id="editAuctionTitle" type='text'
            placeholder='e.g. UCLA Auction'
            value={title}
            disabled={isReadOnly}
            onChange={(e) => setTitle(e.target.value)}/>  
        </div>
        <div className={css['edit-item-input']}>
          <label>Description</label>
          <label> - for weblinks use this format: <b>[link text](https://example.com)</b></label>
          <label> - for italics use this format: <b>{italicLabel}</b></label>
            <textarea id="editAuctionDescription"
              placeholder='Description of the auction goes here ...'
              value={description}
              disabled={isReadOnly}
              rows="6"
              onChange={(e) => setDescription(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Auction Location</label>
            <input id="editAuctionLocation" type='text'
              placeholder='e.g. Los Angeles, CA'
              value={auctionLocation}
              disabled={isReadOnly}
              onChange={(e) => setAuctionLocation(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Auction URL
            <span className={css['label-notice']}>(cannot be changed after creation; recommended to include the current year)
            <br /> (this will appear after auctions.support2.ucla.edu/)</span></label>
            <input id="editAuctionCode" type='text'
              value={auctionCode}
              disabled={true}
              onChange={(e) => setAuctionCode(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Start Time (Pacific Time)</label>
            <input id="editAuctionStartTime" type='datetime-local'
              disabled={isReadOnly}
              value={pacificStartTime}
              onChange={(e) => setPacificStartTime(e.target.value)}/>
          {invalidStart && <p className={themeCss['error-text']}>{invalidStart}</p>}
        </div>
        <div className={css['edit-item-input']}>
          <label>End Time (Pacific Time)</label>
            <input id="editAuctionEndTime" type='datetime-local'
              disabled={isReadOnly}
              value={pacificEndTime}
              onChange={(e) => setPacificEndTime(e.target.value)}/>
          {invalidEnd && <p className={themeCss['error-text']}>{invalidEnd}</p>}
        </div>

        <div className={css['edit-item-input']}>
          <label>Contact Email for Auction Support</label>
            <input id="editAuctionSupportEmail" type='text'
              placeholder='e.g. ???@ucla.edu'
              value={supportEmail}
              disabled={isReadOnly}
              onChange={(e) => setSupportEmail(e.target.value)}/>
        </div>

        <div className={css['create-item-input-checkin']}>
          <label>Require in-person check in for auction participation?</label>
          <select onChange={handleNeedCheckin} value={needsCheckin === null || needsCheckin === undefined ? '' : needsCheckin ? 'true' : 'false'} disabled={isReadOnly}
            id="editAuctionRequireCheckin">
            {typeof(needsCheckin) !== 'boolean' && <option value=''>-</option>}
            <option value='true'>Yes</option>
            <option value='false'>No</option>
          </select>
          <p>"Yes" will generate a QR code for the attendee. This QR code will be required to be scanned at the event in order for the attendee to participate in the auction. Answer "Yes" for events requiring a ticket for physical in-person auction events.</p>
          <p>"No" will automatically check the user in upon registration. Answer "No" if the auction will be open online for bidding before the physical event occurs, or for free events.</p>
        </div>

        {/* <h5>Organizer Information</h5>
        <div className={css['edit-item-input']}>
          <label>Organizer Address</label>
            <input id="editAuctionOrganizerAddress" type='text'
              placeholder='e.g. UCLA Luskin Conference Center'
              value={organizerAddress}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerAddress(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Organizer Name</label>
            <input id="editAuctionOrganizerName" type='text'
              placeholder='e.g. UCLA Geffen School of Medicine'
              value={organizerName}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerName(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Organizer Website</label>
            <input id="editAuctionOrganizerWebsite" type='text'
              placeholder='e.g. www.ucla.edu'
              value={organizerWebsite}
              disabled={isReadOnly}
              onChange={(e) => setOrganizerWebsite(e.target.value)}/>
        </div>
        
        <div className={css['edit-item-input']}>
          <label>Support Phone</label>
            <input id="editAuctionSupportPhone" type='text'
              placeholder='e.g. 310-???-????'
              value={supportPhone}
              disabled={isReadOnly}
              onChange={(e) => setSupportPhone(e.target.value)}/>
        </div> */}
        <h5>Fund Information</h5>
        <div className={css['edit-item-input']}>
          <label>Fund Name</label>
            <input id="editAuctionFundName"type='text'
              placeholder='e.g. E. Richard Brown Health Policy Impact Fund'
              value={fundName}
              disabled={isReadOnly}
              onChange={(e) => setFundName(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Fund Number (without the '- HZ')</label>
            <input id="editAuctionFundNumber" type='text'
              placeholder='e.g. 63090C'
              value={fundNumber}
              disabled={isReadOnly}
              onChange={(e) => setFundNumber(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Marketing Effort (should start with 'M-')</label>
            <input id="editAuctionMarketingEffort" type='text'
              placeholder='e.g. M-45735'
              value={marketingEffort}
              disabled={isReadOnly}
              onChange={(e) => setMarketingEffort(e.target.value)}/>
        </div>
        <div className={css['edit-item-input']}>
          <label>Banner Image Upload <span className={css['acceptable-text']}>(Accepted file types: .jpg, .jpeg, .png, .avif) </span></label>
          <div>
            <p>Current filename: {bannerImage}</p>
            <div className={`${css['thumbnail-container']} `}>
              <img className={themeCss['contain-image']}src={bannerUrl} alt={`${auction.Title} banner thumbnail`}/>
            </div>
            <UploadFiles imageType={'auction'} auction={auction} 
              refreshQuery={refreshAdminAuctionQuery} pageType={'editAuction'} />
          </div>
        </div>
      </div>
      {(needsRequired || invalidEnd || invalidStart) && 
        <div className={css['required-messages']}>
          {title === '' && <p>Auction Title is missing.</p>}
          {/* {auctionCode === '' ? <p>Auction Code is missing.</p> : invalidCode ? <p>Auction Code is already in use.</p> : ''} */}
          {startTime === '' ? <p>Auction Start Time is missing.</p> : invalidStart ? <p>Auction Start Time is invalid.</p> : ''}
          {endTime === '' ? <p>Auction End Time is missing.</p> : invalidEnd ? <p>Auction End Time is invalid.</p> : ''}
          {/* {supportEmail === '' && <p>Auction Support Email is missing.</p>} */}
        </div>}
      <button id="editAuctionSubmit" disabled={isReadOnly || disableEdit || needsRequired || invalidEnd || invalidStart} className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']}`} onClick={() => onSubmit()} type='submit'>Save Auction</button>
    </div>
  )
}