import BraintreeWebClient from 'braintree-web/client'

import { doApi } from 'services/api'

const getBraintreeClientToken = async (flushToken) => {
  if (!flushToken && localStorage.getItem('braintreeClientToken')) {
    return localStorage.getItem('braintreeClientToken')
  }
  else {
    const newToken = await doApi({route: `braintreeclienttoken`})
    localStorage.setItem('braintreeClientToken', newToken.clientToken)
    return newToken.clientToken
  }
};

export const getBraintreeClientInstance = async () => {
  const braintreeClientToken = await getBraintreeClientToken()

  let newClient
  try {
    newClient = await BraintreeWebClient.create({authorization: braintreeClientToken})
  }
  catch (err) {
    // get fresh token in case it was expired
    console.log(err)
    const newToken = await getBraintreeClientToken(true)
    newClient = await BraintreeWebClient.create({authorization: newToken})
  }

  return newClient;
};

export const savePaymentMethod = async (revenueLookupIdArray, nonce, associatedGifts, primaryEmail, testSuccessEmail) => {
  return await doApi({route: `braintreesavecard`, method: 'POST', noBrowseAsUser: true, data: {revenueLookupIdArray, nonce, associatedGifts, primaryEmail, testSuccessEmail}});
};