import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { adminListAuctions } from '../auctionsadminutil'

import adminCss from '../sass/adminpage.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

import CreateAuction from '../CreateAuction'
import AuctionCard from '../AuctionCard'
import NoAuctionsFound from '../NoAuctionsFound'
import { showLoading } from 'services/util'
import SolicitationReview from '../SolicitionReview'

export default function AdminPreviewAuctionPage(props) {

  const [showAuctionModal, setShowAuctionModal] = useState(false)
  const [previewAuctionList, setPreviewAuctionList] = useState([])

  //used to pass down codes for create auction to validate for no duplicate codes
  const [auctionCodes, setAuctionCodes] = useState([])
  const [hideCloseModalButton, setHideCloseModalButton] = useState()

  const isUserSR = localStorage.getItem('isSolicitationReviewAdmin')

  //to refresh query on changes
  const queryClient = useQueryClient()

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["previewAuctionList"],
    queryFn: async () => await adminListAuctions('PREVIEW'),
    staleTime: 0
  })

  useEffect(() => {
    showLoading(isLoading)

    if (error) {
      console.log("Auctions not loaded: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (auctions) {
      console.log('loaded results, ', auctions)
    }
  }, [auctions, error, isLoading])

  const showCreateAuctionModal = () => {
    let newValue = !showAuctionModal
    setShowAuctionModal(newValue)
  }

  const refreshPreviewAuctionQuery = () => {
    queryClient.invalidateQueries(["previewAuctionList"])
  }

  useEffect(() => {
    if (!auctions) return

    const sortedAuctions = [...auctions].sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime));
    // const previewAuctions = sortedAuctions.filter((auction) => auction.Status === 'PREVIEW')

    setPreviewAuctionList(sortedAuctions)
    setAuctionCodes(auctions.map(auction => auction.AuctionCode))
  }, [auctions])

  if (isUserSR) return (
    <>
      {previewAuctionList?.length > 0
        ? previewAuctionList.filter(auction => !!auction.ReviewStatus).map((auction) => <SolicitationReview auctionDetail={auction} refreshAdminAuctionQuery={refreshPreviewAuctionQuery} key={auction.AuctionId} />)
        : isLoading ?  <></> : <NoAuctionsFound status={'preview'} />
      }
    </>
  )

  return (
    <div>
      <div className={adminCss['auction-blocks']}>
        <h2 className={adminCss['auction-headers']}>Preview Auctions</h2>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${adminCss['button-create']}`} onClick={()=>showCreateAuctionModal()}>
          Create New Auction Event
        </button>
        {previewAuctionList?.length > 0
          ? previewAuctionList.map((auction) => <AuctionCard state={'upcoming'} history={props.history} auction={auction} key={auction.AuctionId} />)
          : isLoading ?  <></> : <NoAuctionsFound status={'preview'} />
        }
      </div> 


      {showAuctionModal && 
        <>
          <div className={themeCss['modal-background']} onClick={()=>showCreateAuctionModal()}></div>
          <div className={adminCss['auction-modal']}>
            {!hideCloseModalButton && <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${themeCss['centered-button']}`} onClick={()=>showCreateAuctionModal()} >Close Modal</button>}
            <CreateAuction refreshPreviewAuctionQuery={refreshPreviewAuctionQuery} auctionCodes={auctionCodes} history={props.history}
              showCreateAuctionModal={showCreateAuctionModal} setHideCloseModalButton={setHideCloseModalButton} />
          </div>
        </>
      }
    </div>
  )
}