import { StyledMenu, StyledNavLink } from 'common/header/MenuStyles'
import { useSelector } from 'react-redux';
import { getAuctionUser } from '../redux/selectors';

export default function Menu(props) {

  const auctionUser = useSelector(getAuctionUser);

  // hide My Auctions and My Items in preview mode so we don't get confusing results when Start and End time is spoofed
  const auctionsLink = !sessionStorage.previewAuctionCode && !localStorage.isSolicitationReviewAdmin &&
                       <li>
                         <StyledNavLink
                            onClick={props.hideHeaderMenu}
                            className="bold header-links"
                            id="headerAuctionsLink"
                            to="/myauctions">
                            MY AUCTIONS
                          </StyledNavLink>
                      </li>

  const itemsLink = !sessionStorage.previewAuctionCode && !localStorage.isSolicitationReviewAdmin &&
                    <li>
                      <StyledNavLink
                        onClick={props.hideHeaderMenu}
                        className="bold header-links"
                        id="headerAuctionsLink"
                        to="/myitems">
                        MY ITEMS
                      </StyledNavLink>
                    </li>

  const isAuctionAdmin = localStorage.isAuctionAdmin && !sessionStorage.previewAuctionCode // don't show admin link in Preview Mode
  const adminLink = isAuctionAdmin &&
                    <li>
                      <StyledNavLink
                        onClick={props.hideHeaderMenu}
                        className="bold header-links"
                        id="headerAdminLink"
                        to="/admin/listpreviewauctions">
                        ADMIN
                      </StyledNavLink>
                    </li>

  return (
    <>
      {auctionUser?.UserId &&
        <StyledMenu id="headerLinks">
          {adminLink}
          {auctionsLink}
          {itemsLink}
        </StyledMenu>}
    </>
  )
}